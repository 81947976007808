import React from "react";
import logoATD from "../../assets/LogoAtd/Logo_Atd.png";
import "./Logo.scss";
import { Link } from "react-router-dom";

function Logo({ forPage }) {
  return (
    <Link to="/">
      <picture className={forPage}>
        <img src={logoATD} alt="Atd-Logo" />
      </picture>
    </Link>
  );
}

export default Logo;