const sliderData = [
  {
    imagen: "/assets/Home/Servicios/Formacion.jpg",
    titulo: "Formación y Capacitación",
    descripcion: "Por adquirir nuestra tecnología se realiza formación y capacitación en su manejo.",
  },

  {
    imagen: "/assets/Home/Servicios/Repuestos.jpg",
    titulo: "Repuestos y Recambios",
    descripcion: "Por ser distribuidor autorizado de nuestras marcas disponemos de repuestos de nuestros productos.",
  },

  {
    imagen: "/assets/Home/Slider_Mobile/HomeSlide3.png",
    titulo: "Importadores directos",
    descripcion: "Somos representantes y distribuidores de las marcas de Soldamatic, Proxxon, Fischertecnik y Christiani.",
  },
];
export default sliderData;
