import React from "react";
import sliderService from "../../utils/SliderServices";
import "./SliderPicture2.scss";
import { Carousel } from "antd";

const contentStyle = {
  height: "200px",
  width: "100%",
  display: "flex",
  objectFit: "cover",
};

function SliderPicture2() {
  return (
    <Carousel className="Dots">
      {sliderService.map((item, i) => (
        <div className="Card2" key={i}>
          <div className="header">
            <div className="img-box">
              <img
                alt="Card Servicios"
                style={contentStyle}
                src={`${process.env.PUBLIC_URL}${item.imagen}`}
              />
            </div>
            <h1 className="title">{item.titulo}</h1>
          </div>
          <div className="content">
            <p>{item.descripcion}</p>
          </div>
        </div>
      ))}
    </Carousel>
  );
}

export default SliderPicture2;
