import React from "react";
import HeaderPages from "../../components/HeaderPages/HeaderPages";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import "./HyperRealSim.scss";
import Back from "@mui/icons-material/Reply";
import Videos from "../../components/Videos/Videos";
import ButtonImg from "../../assets/ButtonsImg/ButtonVideoFT.png";

function Industria40() {
  const Fischertechnik = "Fischertechnik";
  //Video
  const tituloPage = Fischertechnik;
  const descriptionPage = "";
  const video = "https://www.youtube.com/watch?v=Ftk7sJneGu4&themeRefresh=1";

  const handleBack = () => {
    window.history.back();
  };

  return (
    <div>
      <HeaderPages brand={Fischertechnik} />
      <section className="BrandPage__Bread">
        <Breadcrumb />
      </section>
      <section className="NewContainer">
        <section className="NewContainer__ImgTitle">
          <section className="ProductContainer__Header">
            <Back
              onClick={handleBack}
              fontSize="large"
              color="primary"
              className="ProductContainer__Back"
            />
            <h3>Industria 4.0</h3>
          </section>
        </section>
        <h3>Modelos de simulación para la industria y las universidades</h3>
        <article className="NewContainer__Text">
          <p>&nbsp;</p>
          <p>
            Los modelos de simulación y los modelos de formación ofrecen a la
            industria y a las universidades la posibilidad de representar de
            forma realista sistemas técnicos complicados y garantizan una
            simulación perfecta para que puedan ser comprendidos por todos. Las
            conexiones del sistema flexible y modular fischertechnik con
            actuadores y sensores adecuados para la industria, así como con los
            controladores de los principales fabricantes, abren posibilidades
            casi ilimitadas para la simulación de hardware. De este modo, los
            modelos de simulación y entrenamiento de fischertechnik abren a la
            industria, la formación y las escuelas posibilidades flexibles para
            probar procesos y enseñar fundamentos técnicos de forma sencilla.
          </p>
          <p>&nbsp;</p>
        </article>
        <article className="NewContainer__Text">
          <Videos
            imgButton={ButtonImg}
            Titulo={tituloPage}
            Description={descriptionPage}
            Video={video}
          />
          <p>&nbsp;</p>
          <p>
            La fischertechnik learning factory 4.0 permite mapear y simular el
            proceso de pedido, el proceso de producción y el proceso de entrega
            en pasos de proceso digitalizados y conectados en red. Permite un
            aprendizaje en profundidad a través del agarre háptico en una imagen
            de producción realista y, por lo tanto, es ideal para la enseñanza,
            la investigación y el desarrollo.
          </p>
          <p>&nbsp;</p>
        </article>
        <p>&nbsp;</p>
        <h3>Modelos de simulación 24V de fischertechnik</h3>
        <article className="NewContainer__Text">
          <p>&nbsp;</p>
          <p>
            Nuestros modelos de simulación y demostración de fischertechnik,
            compactos y listos para montar, son ideales para la formación y el
            perfeccionamiento en la industria. Los modelos disponen del estándar
            industrial mundial de 24V y se programan mediante PLC. Con nuestros
            modelos de simulación de 24 V se pueden modelar procesos de pedido,
            procesos de producción y procesos de suministro en pasos de proceso
            digitalizados y conectados en red.
          </p>
        </article>
        <p>&nbsp;</p>
        <img
          style={{ width: "300px" }}
          src="/assets/News/Industria40_2.png"
          alt=""
        />
        <p>&nbsp;</p>
        <h3>Modelos de simulación 9V de fischertechnik</h3>
        <article className="NewContainer__Text">
          <p>&nbsp;</p>
          <p>
            Nuestros modelos de demostración y formación compactos ya montados
            de fischertechnik son adecuados para demostrar aplicaciones
            industriales modernas y digitalizadas, por lo que son ideales para
            aprender y comprender las aplicaciones de la Industria 4.0 en
            escuelas de formación profesional y formación, así como para su uso
            en investigación, enseñanza y desarrollo en universidades, en
            empresas y departamentos de TI.
          </p>
          <p>&nbsp;</p>
        </article>
      </section>
    </div>
  );
}

export default Industria40;
