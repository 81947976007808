import React from "react";
import { Carousel } from "primereact/carousel";
import "./YouTubeSlider.scss";

const YouTubeSlider = ({ videos }) => {
  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "768px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const itemTemplate = (video) => {
    return (
      <div className="frameContainer">
        <iframe
          title={video.title}
          width="100%"
          height="315"
          src={`https://www.youtube.com/embed/${getVideoIdFromUrl(video.url)}`}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    );
  };

  return (
    <div className="youtubeSliderContainer">
      <Carousel
        value={videos}
        responsiveOptions={responsiveOptions}
        itemTemplate={itemTemplate}
        numVisible={3}
        numScroll={3}
        className="custom-carousel"
        circular
        autoplayInterval={8000}
      ></Carousel>
    </div>
  );
};

const getVideoIdFromUrl = (url) => {
  const match = url.match(/[?&]v=([^?&]+)/);
  return match ? match[1] : "";
};

export default YouTubeSlider;
