import React from "react";
import "./Footer.scss";
import Logo from "../Logo/Logo";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import PlaceIcon from "@mui/icons-material/Place";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Link } from "react-router-dom";

function Footer() {
  const getYear = () => {
    return new Date().getFullYear();
  };

  return (
    <footer className="footer-container">
      <Logo forPage="footer" />
      <section className="footer-grid">
        <section className="marcas">
          <span className="marcas__Titulo">MARCAS</span>
          <ul className="marcas__Lista">
            <li>
              <Link to="/Marcas/Soldamatic">Soldamatic</Link>
            </li>
            <li>
              <Link to="/Marcas/Fischertechnik">Fischertechnik</Link>
            </li>
            <li>
              <Link to="/Marcas/Proxxon">Proxxon</Link>
            </li>
            <li>
              <Link to="/Marcas/Christiani">Christiani</Link>
            </li>
          </ul>
        </section>
        <section className="marcas">
          <span className="marcas__Titulo">SERVICIOS</span>
          <ul className="marcas__Lista">
            <li>
              <Link to="/Marcas">Productos y Catálogos</Link>
            </li>
          </ul>
        </section>
        <section className="marcas">
          <span className="marcas__Titulo">NOVEDADES</span>
          <ul className="marcas__Lista">
            <li>
              <Link to="/News/HyperReal-Sim">HyperReal-SIM</Link>
            </li>
            <li>
              <Link to="/News/Industria40">Industria 4.0</Link>
            </li>
          </ul>
        </section>
        <section className="marcas">
          <span className="marcas__Titulo">DATOS</span>
          <ul className="marcas__Contacto">
            <li>
              <PlaceIcon color="#2196F3" className="marcas__lista__Ubicacion" />
              Calle 93 # 15-59 Ofc.303, Bogotá
            </li>
            <li>
              <WhatsAppIcon
                color="#2196F3"
                className="marcas__lista__Ubicacion"
              />
              (+57)311 8203545
            </li>
            <li>
              <PhoneIphoneIcon
                color="#2196F3"
                className="marcas__lista__Ubicacion"
              />
              No se responden llamadas solo mensajes al WhatsApp
            </li>
            <li>
              <MailOutlineIcon
                color="#2196F3"
                className="marcas__lista__Ubicacion"
              />
              info@atdsas.com
            </li>
          </ul>
        </section>
      </section>
      <section className="footer-redes">
        <div>Siguenos en:</div>
        <div className="footer-redes__icons">
          <div className="footer-redes__icons__div">
            <FacebookIcon />
          </div>
          <div className="footer-redes__icons__div">
            <LinkedInIcon />
          </div>
          <div className="footer-redes__icons__div">
            <TwitterIcon />
          </div>
        </div>

        <div>© Todos los derechos reservados {getYear()}</div>
      </section>
    </footer>
  );
}

export default Footer;
