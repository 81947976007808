import React from "react";
import { Link } from "react-router-dom";
import "./Breadcrumb.scss"

function Breadcrumb() {
  const path = window.location.pathname;
  const pathSegments = path.split("/").filter((segment) => segment !== "");
  return (
    <nav>
      <ul>
        <li style={{ display: "inline"}}>
          <Link to="/">Home / </Link>
        </li>
        {pathSegments.map((segment, index) => {
          const url = `/${pathSegments.slice(0, index + 1).join("/")}`;
          const isLast = index === pathSegments.length - 1;
          return (
            <li style={{ display: "inline" }} key={segment}>
              <Link to={url} style={isLast ? {color:"#009CFF"}:{color:"inherit"}}>
                {segment.charAt(0).toUpperCase()}
                {segment.slice(1)}
              </Link>
              {!isLast && " / "}
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

export default Breadcrumb;
