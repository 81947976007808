import React, { useState, useEffect } from "react";
import "./Slider2.scss";

const SliderPic2 = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % images.length);
    }, 4000);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentIndex, images.length]);

  return (
    <div className="slider-container2">
      <div className="slider2-image">
        <img
          src={`/${process.env.PUBLIC_URL}${images[currentIndex]}`}
          alt="Slider"
        />
        <img
          src={`/${process.env.PUBLIC_URL}${images[(currentIndex - 1) < 0 ? currentIndex + 1 : currentIndex - 1]}`}
          alt="Slider"
        />
        <img
          src={`/${process.env.PUBLIC_URL}${images[(currentIndex - 2) < 0 ? currentIndex + 2 : currentIndex - 2]}`}
          alt="Slider"
        />
        <img
          src={`/${process.env.PUBLIC_URL}${images[(currentIndex - 3) < 0 ? currentIndex + 3 : currentIndex - 3]}`}
          alt="Slider"
        />
      </div>
    </div>
  );
};

export default SliderPic2;
