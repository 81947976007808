const sliderData = [
  {
    imagen: "/assets/Home/Slider_Mobile/Slider.png",
    marca: "",
    descripcion: "Oferta valida hasta el 30 de Mayo 2024",
    link: "",
  },
  {
    imagen: "/assets/Home/Slider_Mobile/HomeSlide1.png",
    marca: "Soldamatic",
    descripcion: "Diseñamos soluciones tecnológicas, educativas e innovadoras.",
    link: "Marcas/Soldamatic",
  },
  {
    imagen: "/assets/Home/Slider_Mobile/HomeSlide2.png",
    marca: "Fischertechnik",
    descripcion:
      "Fischertechnik el sistema de construcción modular escalable de origen Alemán.",
    link: "Marcas/Fischertechnik",
  },
  {
    imagen: "/assets/Home/Slider_Mobile/HomeSlide3.png",
    marca: "Proxxon",
    descripcion:
      "La gama PROXXON tiene más de 50 máquinas y somos líderes mundiales en herramientas en miniatura.",
    link: "Marcas/Proxxon",
  },
];
export default sliderData;
