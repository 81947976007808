const videoLinks = [
  {
    title: "Soldamatic Robotics x Kempten Hochschule - Case Study EN",
    url: "https://www.youtube.com/watch?v=zZkmNTHxGbs",
  },
  {
    title: "Smart Robots Pro I fischertechnik",
    url: "https://www.youtube.com/watch?v=LzJ4RdYX-fs",
  },
  {
    title: "STEM Coding Pro Getting Started I Getting Started",
    url: "https://www.youtube.com/watch?v=tw7HLXVp6lw",
  },
];

export default videoLinks;
