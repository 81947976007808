import React, { useState } from "react";
import ContactHeader from "../../layoud/ProductLayoud/ContactHeader";
import "./Contacto.scss";
import { Form, Input, Button, Checkbox, Select, Modal } from "antd";
import {
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  BankOutlined,
} from "@ant-design/icons";
import Flag from "react-world-flags";
import ReCAPTCHA from "react-google-recaptcha";
import Politicas from "../../components/PoliticasSeguridad/Politicas";
import PlaceIcon from "@mui/icons-material/Place";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Mapa from "../../components/Mapa/Mapa";
import { ROUTES_MAIL, configAxios } from "../../utils/AxiosAPI";
import axios from "axios";
import { Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ModalAvisos from "../ModalAvisos/ModalAvisos";

const { Option } = Select;

function Contacto() {
  const backgroundImg = "/assets/Marcas/Page/Fischertechnik/HeaderFischer.png";
  const logo = "/assets/Marcas/Page/Fischertechnik/LogoFischer.png";
  const Titulo = "Contáctenos";
  const [agreed, setAgreed] = useState(false);
  const [countryCode, setCountryCode] = useState("+57");
  const [form] = Form.useForm();
  const [recaptcha, setRecaptcha] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [mailData, setMailData] = useState({});
  const [sent, setSent] = useState(false);
  const navigate = useNavigate();

  const sendMail = async () => {
    try {
      const res = await axios.post(
        ROUTES_MAIL.Contacto,
        {
          to: "info@atdsas.com",
          subject: "Correo enviado desde la pagina ATDSAS.COM",
          nombre: mailData.nombre,
          nombreEmpresa: mailData.nombreEmpresa,
          numContacto: mailData.numContacto,
          correoElectronico: mailData.correoElectronico,
          mensaje: mailData?.mensaje ? mailData?.mensaje : "",
        },
        configAxios
      );
      if (res.statusText === "OK") {
        setSent(true);
        setTimeout(() => {
          navigate("/");
        }, 4000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const showModal = () => {
    setModalOpen(true);
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const onCaptchaChange = (value) => {
    setRecaptcha(value);
    form.setFieldsValue({
      captcha: value,
    });
  };

  const validatePolicy = (rule, value, callback) => {
    if (!value) {
      callback("Por favor acepte las políticas");
    } else {
      callback();
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    // lógica para enviar el formulario
  };

  return (
    <div>
      <ModalAvisos />
      <ContactHeader
        Titulo={Titulo}
        logo={logo}
        backgroundImg={backgroundImg}
      />
      <div className="Container">
        <section className="Contacto">
          <p className="Contacto__Titulo">
            Completa el formulario para contactarnos
          </p>
          <p className="Contacto__Text">Gracias por confiar en nosotros</p>
          <section className="Container__Form">
            <Form onSubmit={handleSubmit}>
              <Form.Item
                label="Nombre y apellido"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese su nombre",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined />}
                  placeholder="Nombre y apellido"
                  onChange={(e) => {
                    setMailData({
                      ...mailData,
                      nombre: e.target.value,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label="Nombre Empresa (Opcional)" name="empresa">
                <Input
                  prefix={<BankOutlined />}
                  placeholder="Nombre de la empresa"
                  onChange={(e) => {
                    setMailData({
                      ...mailData,
                      nombreEmpresa: e.target.value,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Correo electrónico"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese su correo electrónico",
                  },
                  {
                    type: "email",
                    message: "Por favor ingrese un correo electrónico válido",
                  },
                ]}
              >
                <Input
                  prefix={<MailOutlined />}
                  placeholder="Correo electrónico"
                  onChange={(e) => {
                    setMailData({
                      ...mailData,
                      correoElectronico: e.target.value,
                    });
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Número de contacto (Opcional)"
                name="phone"
                rules={[
                  {
                    pattern: /^[0-9]*$/,
                    message: "Por favor ingrese un número de contacto válido",
                  },
                ]}
              >
                <div className="Container__Form__Item">
                  <Select
                    style={{ width: 75 }}
                    className="Container__Form__Item__Select"
                    value={countryCode}
                    onChange={(value) => setCountryCode(value)}
                  >
                    <Option
                      className="Container__Form__Item__Select__Option"
                      value="+57"
                    >
                      <Flag code="CO" width={28} style={{ paddingTop: 5 }} />
                    </Option>
                    <Option
                      className="Container__Form__Item__Select__Option"
                      value="+52"
                    >
                      <Flag code="MX" width={28} style={{ paddingTop: 5 }} />
                    </Option>
                    <Option
                      className="Container__Form__Item__Select__Option"
                      value="+49"
                    >
                      <Flag code="DE" width={28} style={{ paddingTop: 5 }} />
                    </Option>
                    <Option
                      className="Container__Form__Item__Select__Option"
                      value="+1"
                    >
                      <Flag code="US" width={28} style={{ paddingTop: 5 }} />
                    </Option>
                    <Option
                      className="Container__Form__Item__Select__Option"
                      value="+55"
                    >
                      <Flag code="BR" width={28} style={{ paddingTop: 5 }} />
                    </Option>
                  </Select>
                  <Input
                    prefix={<PhoneOutlined />}
                    placeholder="Número de contacto"
                    onChange={(e) => {
                      setMailData({
                        ...mailData,
                        numContacto: `${countryCode} ${e.target.value}`,
                      });
                    }}
                  />
                </div>
              </Form.Item>

              <Form.Item
                label="Mensaje"
                name="message"
                rules={[
                  { required: true, message: "Por favor ingrese un mensaje" },
                ]}
              >
                <Input.TextArea
                  placeholder="Escriba aquí su mensaje"
                  onChange={(e) => {
                    setMailData({
                      ...mailData,
                      mensaje: e.target.value,
                    });
                  }}
                />
              </Form.Item>

              <Form.Item name="captcha" className="Container__Form__Item">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_GOOGLE_API_CAPTCHA}
                  onChange={onCaptchaChange}
                />
              </Form.Item>
              <Form.Item
                name="policy"
                rules={[
                  {
                    required: true,
                    validator: validatePolicy,
                  },
                ]}
                valuePropName="checked"
                className="Container__Form__Item"
              >
                <Checkbox
                  checked={agreed}
                  onChange={(event) => setAgreed(event.target.checked)}
                >
                  <p onClick={() => showModal()}>
                    Acepto las políticas de privacidad y términos de uso
                  </p>
                </Checkbox>
              </Form.Item>

              <Form.Item name="envio" className="Container__Form__Item">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!recaptcha}
                  onClick={async (e) => {
                    e.preventDefault();
                    sendMail();
                  }}
                >
                  Enviar
                </Button>
              </Form.Item>
              {sent && <Alert>Correo enviado exitosamente!</Alert>}
            </Form>
          </section>
        </section>
        <Modal
          open={modalOpen}
          onCancel={handleCancel}
          footer={null}
          closable={false}
          width={800}
          style={{ padding: "0px" }}
          bodyStyle={{ overflowY: "auto", maxHeight: "500px", padding: "0px" }}
          centered={true}
        >
          <Politicas />
        </Modal>
        <section className="Contacto2">
          <article className="Container__Ubicacion">
            <div className="Container__Ubicacion__MapContainer">
              <Mapa lat={4.6770528550640975} lng={-74.05257599479854} />
            </div>
            <h2>¿Dónde ubicarnos?</h2>
            <p>
              <PlaceIcon
                color="#006DBF"
                className="Container__Ubicacion__Icon"
              />{" "}
              Calle 93 # 15-59 Ofc.303, Bogotá
            </p>
            <p>
              <WhatsAppIcon
                color="#006DBF"
                className="Container__Ubicacion__Icon"
              />{" "}
              (+57)311 8203545
            </p>
            <p>
              <AccessTimeIcon
                color="#006DBF"
                className="Container__Ubicacion__Icon"
              />
              L - V: 8:00 - 18:00
            </p>
            <h3>Chatea con nosotros</h3>
            <section className="Contacto2__Img">
              <a
                href="https://wa.me/573118203545"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/assets/Whatsapp/Whatsapp.png" alt="" />
              </a>
            </section>
          </article>
        </section>
      </div>
    </div>
  );
}

export default Contacto;
