import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../Logo/Logo";
import "./Header.scss";
import Equis from "@mui/icons-material/CloseOutlined";
import Burger from "@mui/icons-material/MenuOutlined";
import Moon from "@mui/icons-material/DarkModeOutlined";
import Sun from "@mui/icons-material/WbSunnyOutlined";
import { useThemeContext } from "../../context/ThemeContext";
import FooterMenuMobile from "../FooterMenuMobile/FooterMenuMobile";
import { MotionAnimate } from "react-motion-animate";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PlaceIcon from "@mui/icons-material/Place";
import Mail from "@mui/icons-material/EmailOutlined";
import { Collapse } from "@mui/material";
import Down from "@mui/icons-material/ArrowDropDown";
import Up from "@mui/icons-material/ArrowDropUp";

function Header() {
  const [menu, setMenu] = useState(false);
  const [submenu, setSubMenu] = useState(false);

  const { setContextTheme } = useThemeContext();
  const [dark, setDark] = useState(false);

  document.body.style.overflow = menu ? "hidden" : "auto";

  const handleMenuToggle = () => {
    setMenu(!menu);
    setSubMenu(false);
  };

  const handleSwitch = () => {
    setContextTheme((state) => (state === "Light" ? "Dark" : "Light"));
  };

  return (
    <div className="container">
      <header className="header-container">
        <Logo forPage="header" onClick={() => setMenu(!menu)} />
        <button onClick={handleMenuToggle}>
          {menu ? (
            <>
              <Equis className="Icon1" style={{ opacity: 1 }} />
              <Burger className="Icon2" style={{ opacity: 0 }} />
            </>
          ) : (
            <>
              <Equis className="Icon1" style={{ opacity: 0 }} />
              <Burger className="Icon2" style={{ opacity: 1 }} />
            </>
          )}
        </button>
      </header>
      {menu && (
        <MotionAnimate
          animation="fadeInUp"
          reset={true}
          distance={100}
          delay={0.2}
          speed={0.4}
        >
          <nav className="MenuMobile">
            <div className="MenuMobile__DarkMode">
              {dark ? (
                <Sun
                  onClick={() => {
                    setDark(false);
                    handleSwitch();
                  }}
                />
              ) : (
                <Moon
                  onClick={() => {
                    setDark(true);
                    handleSwitch();
                  }}
                />
              )}
            </div>
            <main className="MenuMobile__MenuPrincipal">
              <div className="MenuMobile__MenuPrincipal__Container">
                <Link to="/" onClick={() => setMenu(!menu)}>
                  Inicio
                </Link>
                <Link to="/Marcas" onClick={() => setMenu(!menu)}>
                  Productos y Catálogos
                </Link>
                <Link to="" onClick={() => setSubMenu(!submenu)}>
                  Marcas {submenu ? <Up /> : <Down />}
                </Link>
                <Collapse
                  in={submenu}
                  timeout="auto"
                  unmountOnExit
                  sx={{ width: 900 }}
                >
                  <div className="MenuMobile__MenuPrincipal__Container__Collapse">
                    <Link
                      to="/Marcas/Soldamatic"
                      onClick={() => setMenu(!menu)}
                    >
                      Soldamatic
                    </Link>
                    <Link
                      to="/Marcas/Fischertechnik"
                      onClick={() => setMenu(!menu)}
                    >
                      Fischertechnik
                    </Link>
                    <Link to="/Marcas/Proxxon" onClick={() => setMenu(!menu)}>
                      Proxxon
                    </Link>
                    <Link
                      to="/Marcas/Christiani"
                      onClick={() => setMenu(!menu)}
                    >
                      Christiani
                    </Link>
                  </div>
                </Collapse>
                <Link to="/Clientes" onClick={() => setMenu(!menu)}>
                  Clientes y Aliados
                </Link>
                <Link to="/Contacto" onClick={() => setMenu(!menu)}>
                  Contacto
                </Link>
              </div>
            </main>
            <div className="MenuMobile__DatosContacto">
              <p>¡Contáctenos ya!</p>
              <p>
                &emsp;
                <WhatsAppIcon />
                &nbsp;+57 311 820-3545{" "}
              </p>
              <p>
                &emsp;
                <Mail />
                &nbsp;info@atdsas.com{" "}
              </p>
              <p>
                &emsp;
                <PlaceIcon />
                &nbsp;Calle 93 #15 – 59 Of. 303, Bogotá{" "}
              </p>
            </div>
            <FooterMenuMobile />
          </nav>
        </MotionAnimate>
      )}
    </div>
  );
}

export default Header;
