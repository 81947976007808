import React from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import "./OtrosHeader.scss";

function OtrosHeader(props) {
  

  return (
    <>
      <section
        className="Otros__Header"
        style={{
          background: `linear-gradient(
                        0deg,
                        rgba(37, 52, 74, 0.5),
                        rgba(37, 52, 74, 0.5)
                        ),url(assets/Marcas/Marcas.jpg)`,
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
      </section>
      <section className="Otros__Bread">
        <Breadcrumb />
      </section>
        
    </>
  );
}

export default OtrosHeader;
