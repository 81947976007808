import React, { useState, useEffect } from "react";
import "./Slider.scss";

const SliderPic = ({images}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    if (currentIndex === 0) {
      setCurrentIndex(images.length - 1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  };
  
  const handleNext = () => {
    if (currentIndex === images.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % images.length);
    }, 4000);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentIndex, images.length]);


  return (
    <div className="slider-container">
      <div className="slider-image">
        <img src={`/${process.env.PUBLIC_URL}${images[currentIndex]}`} alt="Slider" />
      </div>
      <button className="slider-prev-btn" onClick={handlePrev}>
        &#10094;
      </button>
      <button className="slider-next-btn" onClick={handleNext}>
        &#10095;
      </button>
    </div>
  );
};

export default SliderPic;
