import React from "react";
import "./Imgmarcas.scss";
import { Link } from "react-router-dom";

function Imgmarcas(props) {
  const link = props.name === "Fischertechnik" ? `/Marcas/${props.name}${props.products}` : `/Marcas/${props.name}`;
  return (
    <Link className="ImagenMarca" to={link}>
      <img src={`${props?.img}`} alt={`${props.name}`} />
    </Link>
  );
}

export default Imgmarcas;
