import React, { useState, useEffect } from "react";
import "./SearchBar.scss";
import { IconButton, InputBase } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Search from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import { Modal } from "antd";
import SliderPic from "../Slider/Slider";

function SearchBar({ data, brand }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [imagenes, setImagenes] = useState([]);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
    const results = data.filter((item) =>
      item.product_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setSearchResults(results);
  };

  const showModal = (result) => {
    const imgs = [];
    result.product_imagen_1 !== "" && imgs.push(result.product_imagen_1);
    result.product_imagen_2 !== "" && imgs.push(result.product_imagen_2);
    result.product_imagen_3 !== "" && imgs.push(result.product_imagen_3);
    result.product_imagen_4 !== "" && imgs.push(result.product_imagen_4);
    result.product_imagen_5 !== "" && imgs.push(result.product_imagen_5);
    setModalOpen(true);
    setImagenes(imgs);
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(1);
    const results = data.filter((item) =>
      item.product_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setSearchResults(results);
  };

  const itemsPerPage = 3;
  const totalPages = Math.ceil(searchResults.length / itemsPerPage);
  const paginatedResults = [];
  for (let i = 0; i < totalPages; i++) {
    const startIndex = i * itemsPerPage;
    const pageResults = searchResults.slice(
      startIndex,
      startIndex + itemsPerPage
    );
    paginatedResults.push(pageResults);
  }

  useEffect(() => {
    const results = data.filter((item) => item);
    setSearchResults(results);
  }, [data]);

  return (
    <>
      <form onSubmit={handleSearchSubmit}>
        <div className="searchContainer">
          <IconButton className="searchIcon" onClick={handleSearchSubmit}>
            <Search />
          </IconButton>
          <InputBase
            placeholder="Buscar..."
            value={searchQuery}
            onChange={handleSearchInputChange}
            onBlur={handleSearchSubmit}
            className="searchInput"
          />
        </div>
      </form>
      <div className="resultContainer">
        {paginatedResults[currentPage - 1]?.map((item) => (
          <section
            className="resultContainer__resultCard"
            key={item.id_product}
          >
            <img
              className="resultContainer__resultCard__Img"
              src={`/${process.env.PUBLIC_URL}${item.product_imagen_1}`}
              alt=""
              onClick={() => {
                showModal(item);
              }}
            />
            <p className="resultContainer__resultCard__Brand">
              Fuente de imagen: {item.Marcas.brand_name}
            </p>
            <Link
              className="resultContainer__resultCard__Url"
              to={`/Product/${item.Marcas.brand_name}/${item.id_product}`}
            >
              <div className="resultContainer__resultCard__ContentDiv">
                <h2>{item.product_name}</h2>
                <p>{item.product_description.slice(0, 152) + "..."}</p>
                <div className="resultContainer__resultCard__ContentDiv__UrlDiv">
                  <p>
                    Ver más
                    <SendIcon />
                  </p>
                </div>
              </div>
            </Link>
          </section>
        ))}
        <Pagination
          count={Math.ceil(searchResults.length / itemsPerPage)}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
          color="primary"
        />
        <Modal
          open={modalOpen}
          onCancel={handleCancel}
          footer={null}
          closable={false}
          bodyStyle={{ padding: "0px" }}
          width={800}
          centered={true}
        >
          <SliderPic images={imagenes} />
          <p className="resultContainer__resultCard__Brand">
            Fuente de imagen: {brand}
          </p>
        </Modal>
      </div>
    </>
  );
}

export default SearchBar;
