import React from "react";
import { RUOTES_PRODUCTOS, RUOTES_CATALOGOS } from "../../utils/AxiosAPI";
import { useState } from "react";
import getData from "../../utils/GetDataApi";
import { useEffect } from "react";
import OtrosHeader from "../../layoud/ProductLayoud/OtrosHeader";
import SearchBar from "../../components/SearchBar/SearchBar";
import SearchBarDesktop from "../../components/SearchBarDesktop/SearchBarDesktop";
import "./Marcas.scss";
import videoLinks from "../../utils/VideoNovedades";
import YouTubeSlider from "../../components/YoutubeSlider/YouTubeSlider";
import SliderPdf from "../../components/SliderPdf/SliderPdf";

function Marcas() {
  const [products, setProducts] = useState([]);
  const [catalogos, setCatalogos] = useState([]);

  const getCatalogos = async () => {
    const data = await getData(
      `${RUOTES_CATALOGOS.getAllCatalogos}`,
      `allCatalogos`
    );
    setCatalogos(data);
  };

  const backgroundImg = "/assets/Marcas/Page/Fischertechnik/HeaderFischer.png";
  const logo = "/assets/Marcas/Page/Fischertechnik/LogoFischer.png";

  const brand = "Fischertechnik";
  const getProductos = async () => {
    const data = await getData(
      `${RUOTES_PRODUCTOS.getAllProducts}`,
      "AllProducts"
    );
    setProducts(data);
  };

  useEffect(() => {
    getProductos();
    getCatalogos();
  }, []);

  return (
    <div>
      <OtrosHeader brand={brand} logo={logo} backgroundImg={backgroundImg} />
      <section className="Titulo">
        <h2>Nuestros productos</h2>
      </section>
      <section className="SearchBarMobile">
        <SearchBar data={products} brand={brand} />
      </section>
      <section className="SearchBarDesktop">
        <SearchBarDesktop data={products} brand={brand} items={10} />
      </section>
      <section className="CatalogosSection">
        <section className="Titulo">
          <h2>Catálogos</h2>
        </section>
        <article className="BrandPage__Catalogos__SlidePDF">
          <SliderPdf catalogos={catalogos} />
        </article>
      </section>
      <section className="Home__Youtube">
        <h2>Video Novedades</h2>
        <YouTubeSlider videos={videoLinks} />
      </section>
    </div>
  );
}

export default Marcas;
