class ViewSDKClient {
    constructor() {
      this.readyPromise = new Promise((resolve) => {
        if (window.AdobeDC) {
          resolve();
        } else {
          document.addEventListener("adobe_dc_view_sdk.ready", () => {
            resolve();
          });
        }
      });
      this.adobeDCView = undefined;
    }
  
    ready() {
      return this.readyPromise;
    }
    previewFile(divId, viewerConfig, fileName, url) {
      const config = {
        clientId: `${process.env.REACT_APP_ACROBAT_TOKEN}`,
      };
      if (divId) {
        config.divId = divId;
      }
  
      config.locale = "es-ES";    
      this.adobeDCView = new window.AdobeDC.View(config);
      const previewFilePromise = this.adobeDCView.previewFile(
        {
          content: {
            location: {
              url: url,
            },
          },
          metaData: {
            fileName: fileName,
          },
        },
        viewerConfig
      );
  
      return previewFilePromise;
    }
  }
  
  export default ViewSDKClient;
  