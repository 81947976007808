import React from "react";
import "./PagesStyles.scss";
// import { Link } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
// import SendIcon from "@mui/icons-material/Send";
import Videos from "../../components/Videos/Videos";
import ButtonImg from "../../assets/ButtonsImg/Button_Img_Proxxon.png";
// import Tarjetas from "../../components/TarjetasPages/Tarjetas";
import SliderPdf from "../../components/SliderPdf/SliderPdf";
import getData from "../../utils/GetDataApi";
import { RUOTES_CATALOGOS } from "../../utils/AxiosAPI";
import { useState } from "react";
import { useEffect } from "react";

function Proxxon() {
  const tituloPage = "Proxxon";
  const descriptionPage = "Quieres saber más sobre Fischertechnik, clic acá!";
  const video = "https://www.youtube.com/watch?v=FUPSg4DUPtE";
  const backgroundImg = "/assets/Marcas/Page/Proxxon/HeaderProxxon.png";

  const [catalogos, setCatalogos] = useState([]);

  const getCatalogos = async () => {
    const data = await getData(
      `${RUOTES_CATALOGOS.getCatalogoByBrand}${tituloPage}`,
      `${tituloPage}`
    );
    setCatalogos(data);
  };

  useEffect(() => {
    getCatalogos();
  }, []);

  return (
    <div className="BrandPage">
      <section
        className="BrandPage__Header"
        style={{
          background: `linear-gradient(
          0deg,
          rgba(33, 150, 243, 0.5),
          rgba(33, 150, 243, 0.5)
        ),url(${backgroundImg})`,
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        <div className="BrandPage__Header__DivImg">
          <img
            src="/assets/Marcas/Page/Proxxon/Proxxon.jpg"
            alt="ProxxonLogo"
          />
        </div>
      </section>
      <section className="BrandPage__Bread">
        <Breadcrumb />
      </section>
      <section className="Titulo">
        <h1 id="queEs">Proxxon</h1>
      </section>
      <section className="BrandPage__QueEs">
        <h3>Qué es?</h3>
        <article className="BrandPage__QueEs__Article">
          <p>
            PROXXON es la empresa líder, desde hace casi 30 años, en la
            fabricación de herramientas de precisión de alta calidad. Diferentes
            tipos de profesionales y usuarios de todo el mundo no dudan en
            elegir PROXXON para trabajos de lijado, pulido, corte, fresado,
            cepillado, torneado, tallado, taladradrado y grabado. Poseemos una
            gran variedad de recursos y accesorios para trabajos de bricolaje y
            taller. La gama PROXXON tiene más de 50 máquinas y somos líderes
            mundiales en herramientas en miniatura.
          </p>
          &nbsp;
        </article>
        {/* <div className="BrandPage__QueEs__Cotizar">
          <Link to="/">
            Cotizar
            <SendIcon />
          </Link>
        </div> */}
        <div className="BrandPage__QueEs__Slider">
          <img src="/assets/Marcas/Page/Proxxon/HeaderProxxon.png" alt="" />
        </div>
      </section>
      <section className="BrandPage__Buttons">
        <button
          onClick={() => {
            const queEs = document.getElementById("queEs");
            queEs.scrollIntoView({ behavior: "smooth" });
          }}
        >
          Qué es?
        </button>
        <button
          onClick={() => {
            const Productos = document.getElementById("Productos");
            Productos.scrollIntoView({ behavior: "smooth" });
          }}
        >
          Productos
        </button>
        <button
          onClick={() => {
            const Catalogos = document.getElementById("Catalogos");
            Catalogos.scrollIntoView({ behavior: "smooth" });
          }}
        >
          Catálogos
        </button>
      </section>
      <section className="BrandPage__Catalogos">
        <h3 id="Catalogos">Catálogos</h3>
        <article className="BrandPage__Catalogos__SlidePDF">
          <SliderPdf catalogos={catalogos} />
        </article>
      </section>
      <div id="Productos" className="BrandPage__Video">
        <h3>Videos</h3>
        <Videos
          imgButton={ButtonImg}
          Titulo={tituloPage}
          Description={descriptionPage}
          Video={video}
        />
      </div>
    </div>
  );
}

export default Proxxon;
