import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./ProductId.scss";
import SliderVertical from "../../components/Slider/SliderVertical";
import { useCallback } from "react";
import Back from "@mui/icons-material/Reply";
import TextOrganizer from "../../components/TextOrganizer/TextOrganizer";
import Componente from "@mui/icons-material/ViewInAr";
import Model from "@mui/icons-material/Psychology";
import CountUp from "react-countup";
import HeaderPages from "../../components/HeaderPages/HeaderPages";
import TextOrganizer2 from "../../components/TextOrganizer/TextOrganizer2";
import Hand from "@mui/icons-material/PanToolAlt";
import { useNavigate } from "react-router-dom";

function ProductId() {
  const { id, brand } = useParams();
  const [product, setProduct] = useState([]);
  const [imagenes, setImagenes] = useState([]);
  const [random, setRandom] = useState(null);
  const [random2, setRandom2] = useState(null);
  const [random3, setRandom3] = useState(null);
  const navigate = useNavigate();

  const loadImages = useCallback(() => {
    const imgs = [];
    product.product_imagen_1 !== "" && imgs.push(product.product_imagen_1);
    product.product_imagen_2 !== "" && imgs.push(product.product_imagen_2);
    product.product_imagen_3 !== "" && imgs.push(product.product_imagen_3);
    product.product_imagen_4 !== "" && imgs.push(product.product_imagen_4);
    product.product_imagen_5 !== "" && imgs.push(product.product_imagen_5);
    setImagenes(imgs);
  }, [product]);

  const handleBack = () => {
    window.history.back();
  };

  const randomProducts = (products) => {
    const idsProducts = [];
    products.map((i) => idsProducts.push(i.id_product));
    const productRandom =
      products[Math.floor(Math.random() * idsProducts.length)];
    return productRandom;
  };

  useEffect(() => {
    const products = JSON.parse(localStorage.getItem(`${brand}Products`));
    setRandom(randomProducts(products));
    setRandom2(randomProducts(products));
    setRandom3(randomProducts(products));
    const product = products?.find((p) => p.id_product === id);
    setProduct(product);
  }, [brand, id]);

  useEffect(() => {
    loadImages();
  }, [loadImages]);

  return (
    <>
      <HeaderPages brand={brand} />
      <div className="ProductContainer">
        <section className="ProductContainer__Header">
          <Back
            onClick={handleBack}
            fontSize="large"
            color="primary"
            className="ProductContainer__Back"
          />
          <h3>{product?.product_name}</h3>
        </section>
        <section className="ProductContainer__GaleriaDescripcion">
          <section className="ProductContainer__GaleriaDescripcion__Izq">
            <SliderVertical images={imagenes} />
            <p>Fuente de imagenes: {brand}</p>
          </section>
          <section className="ProductContainer__GaleriaDescripcion__Der">
            <h4>Descripción</h4>
            <TextOrganizer text={product?.product_description} />
          </section>
          <section className="ProductContainer__GaleriaDescripcion__Der__Card">
            <h4>Número de componentes</h4>
            <section className="ProductContainer__GaleriaDescripcion__Der__Card__Components">
              <Componente fontSize="large" />
              <CountUp end={parseInt(product?.num_componentes)} />
            </section>
            {product?.product_models === "0" ? (
              <></>
            ) : (
              <>
                <h4>Número de modelos</h4>
                <section className="ProductContainer__GaleriaDescripcion__Der__Card__Components">
                  <Model fontSize="large" />
                  {product?.product_models === "0" ? (
                    ""
                  ) : (
                    <CountUp end={parseInt(product?.product_models)} />
                  )}
                </section>
              </>
            )}
          </section>
        </section>
        <section className="ProductCaracteristicas">
          <h4>Características</h4>
          <TextOrganizer2 text={product?.product_char} />
        </section>
        <h3>Otros Productos</h3>
        <section className="ProductsRelacionados">
          <section className="ProductsRelacionados__Card2">
            <section className="ProductsRelacionados__Card2__Img">
              <img
                src={`/${process.env.PUBLIC_URL}${random?.product_imagen_1}`}
                alt=""
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/Product/${brand}/${random?.id_product}`);
                }}
              >
                {random?.product_name}
                <Hand />
              </button>
            </section>
          </section>
          <section className="ProductsRelacionados__Card2">
            <section className="ProductsRelacionados__Card2__Img">
              <img
                src={`/${process.env.PUBLIC_URL}${random2?.product_imagen_1}`}
                alt=""
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/Product/${brand}/${random2?.id_product}`);
                }}
              >
                {random2?.product_name}
                <Hand />
              </button>
            </section>
          </section>
          <section className="ProductsRelacionados__Card2">
            <section className="ProductsRelacionados__Card2__Img">
              <img
                src={`/${process.env.PUBLIC_URL}${random3?.product_imagen_1}`}
                alt=""
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/Product/${brand}/${random3?.id_product}`);
                }}
              >
                {random3?.product_name}
                <Hand />
              </button>
            </section>
          </section>
        </section>
      </div>
    </>
  );
}

export default ProductId;
