import React from "react";
import "./PagesStyles.scss";
// import { Link } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
// import SendIcon from "@mui/icons-material/Send";
import Videos from "../../components/Videos/Videos";
import ButtonImg from "../../assets/ButtonsImg/Button_Img_Soldamatic.png";
import Tarjetas from "../../components/TarjetasPages/Tarjetas";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import SliderPdf from "../../components/SliderPdf/SliderPdf";
import getData from "../../utils/GetDataApi";
import { RUOTES_CATALOGOS } from "../../utils/AxiosAPI";
import { useState } from "react";
import { useEffect } from "react";

function Soldamatic() {
  const tituloPage = "Soldamatic";
  const descriptionPage = "Quieres saber más sobre Soldamatic, clic acá!";
  const video = "https://www.youtube.com/watch?v=pzmsxjgTSU8";
  const backgroundImg = "/assets/Marcas/Page/Soldamatic/HeaderSolda.png";

  const [catalogos, setCatalogos] = useState([]);

  const getCatalogos = async () => {
    const data = await getData(
      `${RUOTES_CATALOGOS.getCatalogoByBrand}${tituloPage}`,
      `${tituloPage}`
    );
    setCatalogos(data);
  };

  useEffect(() => {
    getCatalogos();
  }, []);

  return (
    <div className="BrandPage">
      <section
        className="BrandPage__Header"
        style={{
          background: `linear-gradient(
          0deg,
          rgba(33, 150, 243, 0.5),
          rgba(33, 150, 243, 0.5)
        ),url(${backgroundImg})`,
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        <div className="BrandPage__Header__DivImg">
          <img src="/assets/Marcas/Soldamatic.png" alt="SoldamaticLogo" />
        </div>
      </section>
      <section className="BrandPage__Bread">
        <Breadcrumb />
      </section>
      <section className="Titulo">
        <h1 id="queEs">Soldamatic</h1>
      </section>
      <section className="BrandPage__QueEs">
        <h3>Qué es?</h3>
        <article className="BrandPage__QueEs__Article">
          <p>
            Solución de formación en soldadura de última generación desarrollada
            por Soldadores para Soldadores. Lista para usar, flexible y eficaz,
            utiliza Realidad Aumentada, revolucionando la formación en soldadura
            para crear nuevas las generaciones de soldadores.
          </p>
        </article>
        {/* <div className="BrandPage__QueEs__Cotizar">
          <Link to="/">
            Cotizar
            <SendIcon />
          </Link>
        </div> */}
        <section className="BrandPage__Image">
          <div className="BrandPage__Image__Marco">
            Equipo de SIMULADOR EN REALIDAD AUMENTADA
          </div>
          <ReactCompareSlider
            itemOne={
              <ReactCompareSliderImage
                src="/assets/Marcas/Page/Soldamatic/Image_Izquierda.jpg"
                alt="Image one"
              />
            }
            itemTwo={
              <ReactCompareSliderImage
                src="/assets/Marcas/Page/Soldamatic/Image_Derecha.jpg"
                alt="Image two"
              />
            }
          />
        </section>
      </section>
      <section className="BrandPage__Buttons">
        <button
          onClick={() => {
            const queEs = document.getElementById("queEs");
            queEs.scrollIntoView({ behavior: "smooth" });
          }}
        >
          Qué es?
        </button>
        <button
          onClick={() => {
            const Productos = document.getElementById("Productos");
            Productos.scrollIntoView({ behavior: "smooth" });
          }}
        >
          Productos
        </button>
        <button
          onClick={() => {
            const Catalogos = document.getElementById("Catalogos");
            Catalogos.scrollIntoView({ behavior: "smooth" });
          }}
        >
          Catálogos
        </button>
      </section>
      <div id="Productos" className="BrandPage__Video">
        <Videos
          imgButton={ButtonImg}
          Titulo={tituloPage}
          Description={descriptionPage}
          Video={video}
        />
      </div>
      <section className="BrandPage__Products">
        <h3>Productos y Servicios</h3>
        <h3>Soldamatic</h3>
        <article className="BrandPage__Products__Article">
          <p>
            Soldamatic IE ofrece un sistema de suscripción de software flexible,
            adaptado a las necesidades del mercado.
          </p>
          <p>
            Está compuesto por el Hardware de Soldamatic + 3 opciones de
            suscripción de software.
          </p>
        </article>
        <section className="BrandPage__Products__Tarjetas">
          <Tarjetas
            color={"__IconosGreen"}
            back={"/assets/Marcas/Page/Soldamatic/Target_Soldamatic_2.png"}
            log={"/assets/Marcas/Page/Soldamatic/industry.png"}
            text1={
              "Mejora la productividad de las operaciones de soldadura y reduce la tasa de suspensos en procesos de recertificación industriales."
            }
            text2={"Compañías industriales"}
            text3={
              "Mejora la calidad Reduce costes de ineficiencia Ofrece formación continua."
            }
          />
          <Tarjetas
            color={"__IconosBlue"}
            back={"/assets/Marcas/Page/Soldamatic/Target_Soldamatic_3.png"}
            log={"/assets/Marcas/Page/Soldamatic/robotics.png"}
            text1={
              "Atrae a la nueva generación de jóvenes a la soldadura, permite formarlos mejor, de forma más segura, más rápido y reduciendo costes."
            }
            text2={"Escuelas metal-mecánicas, universidades."}
            text3={
              "Atrae estudiantes Reduce costes Inicia a futuros soldadores."
            }
          />
          <Tarjetas
            color={"__IconosGray"}
            back={"/assets/Marcas/Page/Soldamatic/Target_Soldamatic_1.png"}
            log={"/assets/Marcas/Page/Soldamatic/advanced.png"}
            text1={
              "Reduce la distancia con la industria, permite entrenar en RA con piezas de competición y diferentes aplicaciones industriales."
            }
            text2={"Centros de formación de soldadura."}
            text3={
              "Mejora la eficiencia, Reduce costes y Prepara para la industria."
            }
          />
        </section>
      </section>

      <section className="BrandPage__Catalogos">
        <h3 id="Catalogos">Catálogos</h3>
        <article className="BrandPage__Catalogos__SlidePDF">
          <SliderPdf catalogos={catalogos} />
        </article>
      </section>
    </div>
  );
}

export default Soldamatic;
