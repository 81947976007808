import React from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import './ContactHeader.scss';

function ContactHeader(props) {
  return (
    <>
      <section
        className="ContactPage__Header"
        style={{
          background: `linear-gradient(
                        0deg,
                        rgba(33, 150, 243, 0.5),
                        rgba(33, 150, 243, 0.5)
                        ),url(${props.backgroundImg})`,
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        <h2>{props.Titulo}</h2>  
      </section>
      <section className="ContactPage__Bread">
        <Breadcrumb />
      </section>
    </>
  );
}

export default ContactHeader;
