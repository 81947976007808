import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import "./ModalEventos.scss";

const ModalEventos = () => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 20000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Modal
      title={false}
      open={visible}
      onCancel={() => setVisible(false)}
      onOk={() => setVisible(false)}
      footer={null}
      closable={false}
      centered={true}
      width={600}
      className="Modal"
    >
      <section className="Modal-Text">
        {/* <section className="Modal-Img">
          <a href="https://acofi.edu.co/eiei2023/" target="blank">
            <img src="/assets/Eventos/Acofi.png" alt="Acofi" />
            Link
          </a>
          <a href="https://edutechnia.com/es" target="blank">
            <img src="/assets/Eventos/EduTechnia.png" alt="Acofi" />
            Link
          </a>
          <a href="https://expoingenieria.edu.co/" target="blank">
            <img src="/assets/Eventos/ExpoIng.png" alt="Acofi" />
            Link
          </a>
        </section> */}
        <section className="Modal-I">
          <div>
            <img src="/assets/Eventos/Certificado.png" alt="" />
          </div>
        </section>
      </section>
    </Modal>
  );
};

export default ModalEventos;
