import React from "react";
import "./PagesStyles.scss";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import SendIcon from "@mui/icons-material/Send";
import Videos from "../../components/Videos/Videos";
import ButtonImg from "../../assets/ButtonsImg/Button_Img_Fischertechnik.png";
import Tarjetas from "../../components/TarjetasPages/Tarjetas";
import SliderPdf from "../../components/SliderPdf/SliderPdf";
import { RUOTES_CATALOGOS } from "../../utils/AxiosAPI";
import { useState } from "react";
import { useEffect } from "react";
import getData from "../../utils/GetDataApi";

function Fischertechnik() {
  const tituloPage = "Fischertechnik";
  const descriptionPage = "Quieres saber más sobre Fischertechnik, clic acá!";
  const video = "https://www.youtube.com/watch?v=mRCJKG-3Cz0&t=25s";
  const backgroundImg = "/assets/Marcas/Page/Fischertechnik/HeaderFischer.png";

  const [catalogos, setCatalogos] = useState([]);

  const getCatalogos = async () => {
    const data = await getData(
      `${RUOTES_CATALOGOS.getCatalogoByBrand}${tituloPage}`,
      `${tituloPage}`
    );
    setCatalogos(data);
  };

  useEffect(() => {
    getCatalogos();
  }, []);

  return (
    <div className="BrandPage">
      <section
        className="BrandPage__Header"
        style={{
          background: `linear-gradient(
          0deg,
          rgba(33, 150, 243, 0.5),
          rgba(33, 150, 243, 0.5)
        ),url(${backgroundImg})`,
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        <div className="BrandPage__Header__DivImg">
          <img
            src="/assets/Marcas/Page/Fischertechnik/LogoFischer.png"
            alt="FischertechnikLogo"
          />
        </div>
      </section>
      <section className="BrandPage__Bread">
        <Breadcrumb />
      </section>
      <section className="Titulo">
        <h1 id="queEs">Fischertechnik</h1>
      </section>
      <section className="BrandPage__QueEs">
        <h3>Qué es?</h3>
        <article className="BrandPage__QueEs__Article">
          <p>
            Fischertechnik es un sistema de construcción modular escalable de
            origen Alemán. Nace en 1965 como regalo de Navidad para hijos de los
            clientes y colaboradores de la empresa y poco a poco gracias a las
            virtudes del sistema, lo combirtieron en herramienta ideal para
            asistir de manera divertida en la alfabetización tecnológica de la
            niñez y juventud alrededor del mundo, así como en instrumento para
            el diseño electro-mecánico de simulación y validación de procesos
            industriales.
          </p>
          <p>&nbsp;</p>
          <p>
            A través de los fundamentos y conceptos de las disciplinas que
            comprenden la mecatrónica – (mecánica, electrónica, informática y
            control automático) y ligado también a otras ramas de la ciencia y
            física aplicada que en su conjunto engloban el enfoque STEM
            (energías renovables o la óptica), los niños y jóvenes construyen su
            conocimiento, y desarrollan habilidades del pensamiento como
            herramientas para todas la etapas de la vida.
          </p>
        </article>
        <div className="BrandPage__QueEs__Cotizar">
          {/* <Link to="/">
            Cotizar
            <SendIcon />
          </Link> */}
        </div>
        <div className="BrandPage__QueEs__Slider">
          <img
            src="/assets/Marcas/Page/Fischertechnik/PageFischertechnik1.png"
            alt=""
          />
        </div>
      </section>
      <section className="BrandPage__Buttons">
        <button
          onClick={() => {
            const queEs = document.getElementById("queEs");
            queEs.scrollIntoView({ behavior: "smooth" });
          }}
        >
          Qué es?
        </button>
        <button
          onClick={() => {
            const Productos = document.getElementById("Productos");
            Productos.scrollIntoView({ behavior: "smooth" });
          }}
        >
          Productos
        </button>
        <button
          onClick={() => {
            const Catalogos = document.getElementById("Catalogos");
            Catalogos.scrollIntoView({ behavior: "smooth" });
          }}
        >
          Catálogos
        </button>
      </section>
      <div id="Productos" className="BrandPage__Video">
        <Videos
          imgButton={ButtonImg}
          Titulo={tituloPage}
          Description={descriptionPage}
          Video={video}
        />
      </div>
      <section className="BrandPage__Products">
        <h3>Productos y Servicios</h3>
        <h3>Fischertechnik</h3>
        <article className="BrandPage__Products__Article">
          <p>
            El módulo básico fischertechnik se puede adosar en cualquiera de los
            seis lados. Este bloque de construcción «polifacético» forma la base
            de todos los kits de construcción fischertechnik, que están
            agrupados por la edad y las habilidades de los niños.Con ellos, la
            técnica se vive muy de cerca y se entiende jugando.
          </p>
          <p>
            Como miembro de la Fair Toys Organisation (FTO), fischertechnik está
            comprometido con la mejora de los estándares ecológicos y sociales
            en la producción de los juguetes.
          </p>
        </article>
        <div className="BrandPage__QueEs__Cotizar">
          <Link to="/Marcas/Fischertechnik/Productos">
            Ver Productos
            <SendIcon />
          </Link>
        </div>
        <section className="BrandPage__Products__Tarjetas">
          <Tarjetas
            color={"__IconosRed"}
            back={
              "/assets/Marcas/Page/Fischertechnik/Target_Fischertechnik_1.png"
            }
            log={"/assets/Marcas/Page/Fischertechnik/Junior.png"}
            text1={
              "A partir de 5 años de edad comienza la diversión por el juego con fischertechnik."
            }
            text2={
              "La línea JUNIOR permite vivir las primeras experiencias con la técnica y su funcionamiento."
            }
            text3={"El lema es, aprender jugando y diseñar con éxito rápido."}
          />
          <Tarjetas
            color={"__IconosGray"}
            back={
              "/assets/Marcas/Page/Fischertechnik/Target_Fischertechnik_2.png"
            }
            log={"/assets/Marcas/Page/Fischertechnik/Advanced.png"}
            text1={""}
            text2={
              "Niños a partir de 6 años amplían con la línea ADVANCED sus conocimientos y capacidades."
            }
            text3={
              "Los modelos son realistas y comprensibles en su funcionamiento y perfectamente robustos en la construcción y el juego."
            }
          />
          <Tarjetas
            color={"__IconosGray"}
            back={
              "/assets/Marcas/Page/Fischertechnik/Target_Fischertechnik_3.png"
            }
            log={"/assets/Marcas/Page/Fischertechnik/Profi.png"}
            text1={""}
            text2={
              "La línea PROFI a partir de los 7 años ofrece a los técnicos del mañana kits de construcción de gran realismo."
            }
            text3={
              "Temas como mecánica, estática, neumática, electrotécnica o energías renovables garantizan unas vivencias sobresalientes en el mundo de la técnica."
            }
          />
          <Tarjetas
            color={"__IconosGray"}
            back={
              "/assets/Marcas/Page/Fischertechnik/Target_Fischertechnik_4.png"
            }
            log={"/assets/Marcas/Page/Fischertechnik/Robotics.png"}
            text1={""}
            text2={
              "Mediante un software gráfico de fácil manejo, los niños a partir de 5 años pueden programar y controlar modelos y robots fischertechnik con ayuda del PC o de la tablet."
            }
            text3={
              "También es posible controlarlos con aplicaciones en el teléfono inteligente."
            }
          />
          <Tarjetas
            color={"__IconosGray"}
            back={
              "/assets/Marcas/Page/Fischertechnik/Target_Fischertechnik_5.png"
            }
            log={"/assets/Marcas/Page/Fischertechnik/Plus.png"}
            text1={""}
            text2={
              "Como complemento óptimo. Lo que hace a fischertechnik aún más atractivo: fischertechnik PLUS pone luz, movimiento y diversión adicional."
            }
            text3={""}
          />
        </section>
      </section>
      <section className="BrandPage__Catalogos">
        <h3 id="Catalogos">Catálogos</h3>
        <article className="BrandPage__Catalogos__SlidePDF">
          <SliderPdf catalogos={catalogos} />
        </article>
      </section>
    </div>
  );
}

export default Fischertechnik;
