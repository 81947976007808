import React from "react";
import "./PagesStyles.scss";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import SendIcon from "@mui/icons-material/Send";
import Videos from "../../components/Videos/Videos";
import ButtonImg from "../../assets/ButtonsImg/Button_Img_Christiani.png";
import SliderPdf from "../../components/SliderPdf/SliderPdf";
import { RUOTES_CATALOGOS } from "../../utils/AxiosAPI";
import { useState } from "react";
import { useEffect } from "react";
import getData from "../../utils/GetDataApi";

function Christiani() {
  const tituloPage = "Christiani";
  const descriptionPage = "Quieres saber más sobre Christiani, clic acá!";
  const video = "https://www.youtube.com/watch?v=yguP3OQESCw";
  const backgroundImg = "/assets/Marcas/Page/Christiani/HeaderChristiani.png";

  const [catalogos, setCatalogos] = useState([]);

  const getCatalogos = async () => {
    const data = await getData(
      `${RUOTES_CATALOGOS.getCatalogoByBrand}${tituloPage}`,
      `${tituloPage}`
    );
    setCatalogos(data);
  };

  useEffect(() => {
    getCatalogos();
  }, []);

  return (
    <div className="BrandPage">
      <section
        className="BrandPage__Header"
        style={{
          background: `linear-gradient(
          0deg,
          rgba(33, 150, 243, 0.5),
          rgba(33, 150, 243, 0.5)
        ),url(${backgroundImg})`,
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        <div className="BrandPage__Header__DivImg">
          <img
            src="/assets/Marcas/Page/Christiani/Christiani.jpg"
            alt="ChristianiLogo"
          />
        </div>
      </section>
      <section className="BrandPage__Bread">
        <Breadcrumb />
      </section>
      <section className="Titulo">
        <h1 id="queEs">Christiani</h1>
      </section>
      <section className="BrandPage__QueEs">
        <h3>Qué es?</h3>
        <article className="BrandPage__QueEs__Article">
          <p>
            Christiani International es una empresa alemana que ofrece
            soluciones educativas y de formación técnica a nivel mundial. La
            empresa se especializa en la producción y distribución de materiales
            didácticos, equipos de laboratorio, herramientas de medición y otros
            recursos para la formación técnica y profesional.
          </p>
          &nbsp;
          <p>
            La empresa tiene una amplia gama de productos y servicios, desde
            materiales didácticos para la formación en habilidades técnicas
            básicas hasta programas de formación avanzados en campos como la
            automatización industrial, la robótica, la mecánica y la
            electrónica. Además de su catálogo de productos, Christiani
            International también ofrece servicios de consultoría y
            asesoramiento para empresas y organizaciones educativas que buscan
            mejorar la calidad de su formación técnica y profesional.
          </p>
          &nbsp;
          <p>
            Christiani International fue fundada en 1931 y desde entonces ha
            crecido para convertirse en una empresa líder en su campo, con
            clientes en todo el mundo. La empresa tiene su sede en Konstanz,
            Alemania, y cuenta con filiales en varios países de Europa, Asia y
            con ATD SAS como su representante en América Latina.
          </p>
        </article>
        <div className="BrandPage__QueEs__Slider">
          <img
            src="/assets/Marcas/Page/Christiani/PageChristiani1.png"
            alt=""
          />
        </div>
      </section>
      <section className="BrandPage__Buttons">
        <button
          onClick={() => {
            const queEs = document.getElementById("queEs");
            queEs.scrollIntoView({ behavior: "smooth" });
          }}
        >
          Qué es?
        </button>
        <button
          onClick={() => {
            const Productos = document.getElementById("Productos");
            Productos.scrollIntoView({ behavior: "smooth" });
          }}
        >
          Productos
        </button>
        <button
          onClick={() => {
            const Catalogos = document.getElementById("Catalogos");
            Catalogos.scrollIntoView({ behavior: "smooth" });
          }}
        >
          Catálogos
        </button>
      </section>
      <div id="Productos" className="BrandPage__Video">
        <Videos
          imgButton={ButtonImg}
          Titulo={tituloPage}
          Description={descriptionPage}
          Video={video}
        />
      </div>
      <section className="BrandPage__Products">
        <h3>Productos y Servicios</h3>
        <h3>Christiani</h3>
        <article className="BrandPage__Products__Article">
          <p>
            Programas de formación: la empresa ofrece programas de formación
            avanzados en campos como la automatización industrial, la robótica,
            la mecánica, la electrónica y la energía renovable, diseñados para
            preparar a los estudiantes para carreras en sectores técnicos y
            profesionales.
          </p>
          <p>
            Equipos de laboratorio: la empresa ofrece equipos de laboratorio
            para la enseñanza de disciplinas como la mecánica, la electrónica,
            la hidráulica, la neumática, la energía renovable y la
            automatización industrial.
          </p>
          <p>
            Consultoría y asesoramiento: ATD SAS ofrece servicios de consultoría
            y asesoramiento para empresas y organizaciones educativas que buscan
            mejorar la calidad de su formación técnica y profesional.
          </p>
        </article>
        <div className="BrandPage__QueEs__Cotizar">
          <Link to="/Marcas/Christiani/Productos">
            Ver Productos
            <SendIcon />
          </Link>
        </div>
      </section>
      <section className="BrandPage__Catalogos">
        <h3 id="Catalogos">Catálogos</h3>
        <article className="BrandPage__Catalogos__SlidePDF">
          <SliderPdf catalogos={catalogos} />
        </article>
      </section>
    </div>
  );
}

export default Christiani;
