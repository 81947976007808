import React, { useState } from "react";
import slider1 from "../../utils/SliderPrincipal";
import "./SliderPicture.scss";
import { Carousel, Modal } from "antd";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import VisualizadorPDF from "../../components//VisualizadorPDF/VisualizadorPDF";

const contentStyle = {
  height: "400px",
  width: "100%",
  display: "flex",
  objectFit: "cover",
};

function SliderPicture() {
  const [modalOpen, setModalOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [nameCatalogo, setNameCat] = useState("");

  const showModal = () => {
    setModalOpen(true);
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const urlSteam = `${process.env.PUBLIC_URL}/assets/Catalogos/Fischertechnik/SteamFTES2024.pdf`;
  const nameSteam = "STEM CODING PRO";

  return (
    <>
      <Carousel effect="fade" autoplay>
        {slider1.map((item, i) => (
          <div key={i} className="Slider__Div">
            <img
              src={`${process.env.PUBLIC_URL}${item.imagen}`}
              style={contentStyle}
              alt="Imagen Slider"
            />
            {item.marca ? ( // Comprueba si item.marca no es una cadena vacía
              <div className="Info">
                <p className="Info__marca">{item.marca}</p>
                <p className="Info__descrip">{item.descripcion}</p>
              </div>
            ) : (
              <div className="Info2">
                <p className="Info2__descrip2">{item.descripcion}</p>
              </div>
            )}

            <div className="Slider__Div__Butt">
              {item.link ? (
                <Link to={item.link}>
                  <Button>Consultar</Button>
                </Link>
              ) : (
                <Button
                  onClick={() => {
                    showModal();
                    setPdfUrl(urlSteam);
                    setNameCat(nameSteam);
                  }}
                >
                  Consultar
                </Button>
              )}
            </div>
          </div>
        ))}
      </Carousel>
      <Modal
        open={modalOpen}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        bodyStyle={{ padding: "0px" }}
        width={800}
        centered={true}
      >
        <VisualizadorPDF document={nameCatalogo} url={pdfUrl} />
      </Modal>
    </>
  );
}

export default SliderPicture;
