
export const configAxios = {
  headers: {
    'Authorization': process.env.REACT_APP_AUTHORIZATION_TOKEN,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  }, 
};

export const RUOTES_MARCAS = {
  getAllMarcas: `${process.env.REACT_APP_URL_BACKEND}marcas`,
  getMarca: `${process.env.REACT_APP_URL_BACKEND}marcas/marca/`
};

export const RUOTES_CLIENTS = {
  getAllClients: `${process.env.REACT_APP_URL_BACKEND}cliente`,
  getClient: `${process.env.REACT_APP_URL_BACKEND}cliente/client/`
};

export const RUOTES_CATALOGOS = {
  getAllCatalogos: `${process.env.REACT_APP_URL_BACKEND}catalogos`,
  getCatalogo: `${process.env.REACT_APP_URL_BACKEND}catalogos/catalogo/`,
  getCatalogoByBrand: `${process.env.REACT_APP_URL_BACKEND}catalogos/marca/`
};

export const RUOTES_PRODUCTOS = {
  getAllProducts: `${process.env.REACT_APP_URL_BACKEND}productos`,
  getProduct: `${process.env.REACT_APP_URL_BACKEND}productos/product/`,
  getProductsByBrand: `${process.env.REACT_APP_URL_BACKEND}productos/marca/`
};

export const ROUTES_MAIL = {
  Contacto: `${process.env.REACT_APP_URL_BACKEND}correo/Contacto`
};
