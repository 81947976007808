import { Carousel, Modal } from "antd";
import React, { useState } from "react";
import "./SliderPDF.scss";
import Ver from "@mui/icons-material/Visibility";
import VisualizadorPDF from "../VisualizadorPDF/VisualizadorPDF";

function SliderPdf(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [nameCatalogo, setNameCat] = useState("");

  const showModal = () => {
    setModalOpen(true);
  };

  const handleCancel = () => {
    setModalOpen(false);
  };
  return (
    <>
      <Carousel className="Dots">
        <div className="SlidePDF">
          {props.catalogos.map((item) => (
            <div
              className="SlidePDF__Container"
              onClick={() => {
                showModal();
                setPdfUrl(`${process.env.PUBLIC_URL}${item.url_catalogo}`);
                setNameCat(item.nombre_catalogo);
              }}
              key={item.id_catalogo}
            >
              <img
                src={`${process.env.PUBLIC_URL}${item.url_portada}`}
                alt=""
              />
              <p>{item.nombre_catalogo}</p>
              <div className="SlidePDF__Container__Ver">
                <Ver />
              </div>
            </div>
          ))}
        </div>
      </Carousel>

      <Modal
        open={modalOpen}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        bodyStyle={{ padding: "0px" }}
        width={800}
        centered={true}
      >
        <VisualizadorPDF document={nameCatalogo} url={pdfUrl} />
      </Modal>
    </>
  );
}

export default SliderPdf;
