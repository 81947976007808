import React from "react";
import Slider3 from "../../utils/images3";
import "./SliderPicture4.scss";
import { Link } from "react-router-dom";

function SliderPicture4() {
  return (
    <div className="Slider4Container">
      {Slider3.map((item, i) => (
        <div key={i} className="Slider4">
          <div className="Slider4__Div">
            <img src={item.imagen} alt="" />
            <div>
              <p>{item.descripcion}</p>
              <Link to={item.link}>Ver más</Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SliderPicture4;
