import React from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";


function ProductHeader(props) {
  return (
    <>
      <section
        className="BrandPage__Header"
        style={{
          background: `linear-gradient(
                        0deg,
                        rgba(33, 150, 243, 0.5),
                        rgba(33, 150, 243, 0.5)
                        ),url(${props.backgroundImg})`,
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        <div className="BrandPage__Header__DivImg">
          <img
            src={props.logo}
            alt={props.brand}
          />
        </div>
      </section>
      <section className="BrandPage__Bread">
        <Breadcrumb />
      </section>
    </>
  );
}

export default ProductHeader;
