import { configAxios } from "../utils/AxiosAPI";
import axios from "axios";

const getDataFromApi = async (url, cache) => {
  try {
    const response = await axios.get(url, configAxios);
    localStorage.setItem(cache, JSON.stringify(response?.data?.data));
    const data = response?.data?.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

function getDataFromCache(cache) {
  // Obtener los datos de la caché del navegador
  const data = localStorage.getItem(`${cache}`);
  return data ? JSON.parse(data) : null;
}

async function getData(url, cache) {
  const cachedData = getDataFromCache(cache);
  if (cachedData) {
    // Si los datos están en la caché, utilizar los datos de la caché
    return Promise.resolve(cachedData);
  } else {
    // Si los datos no están en la caché, obtener los datos de la API y almacenarlos en la caché
    return await getDataFromApi(url, cache);
  }
}

export default getData;
