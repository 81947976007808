import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Outlet } from "react-router-dom";
import WhatsAppButton from "../../components/WhatsAppButton/WhatsAppButton";
import { useEffect } from "react";
import updateDataFromApi from "../../utils/UpdateDataApi";
import MenuEscritorio from "../../components/MenuEscritorio/MenuEscritorio";
import "./UserLayoud.scss";

function UserLayoud() {
  useEffect(() => {
    updateDataFromApi();
  }, []);
  
  return (
    <section>
      <div className="Mobil">
        <Header />
      </div>
      <div className="Escritorio">
        <MenuEscritorio />
      </div>
      <Outlet />
      <WhatsAppButton />
      <Footer />
    </section>
  );
}

export default UserLayoud;
