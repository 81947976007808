import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { Alert } from "@mui/material";

const ModalAvisos = () => {
  const [visible, setVisible] = useState(true);
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Modal
      title={false}
      open={visible}
      onCancel={() => setVisible(false)}
      onOk={() => setVisible(false)}
      footer={null}
      closable={false}
      centered={true}
    >
      {showAlert && (
        <Alert severity="info" onClose={() => setShowAlert(false)}>
          Recuerde que puede agendar una cita en nuestro formulario de contacto.
          L - V: 8:00 - 18:00
        </Alert>
      )}
      <p></p>
    </Modal>
  );
};

export default ModalAvisos;
