import ViewSDKClient from "../../utils/ViewSDKClient";

function VisualizadorPDF({document, url}) {
  const viewSDKClient = new ViewSDKClient();
  viewSDKClient.ready().then(() => {
    /* Invoke file preview */
    viewSDKClient.previewFile(
      "pdf-div",
      {
        /* Pass the embed mode option here */
        embedMode: "SIZED_CONTAINER"
      },
      `${document}`,
      `${url}`
    );
  });

  return <div id="pdf-div" className="sized-container-div"></div>;
}

export default VisualizadorPDF;
