import React from "react";
import { Menu } from "antd";
import "./MenuEscritorio.scss";
import Logo from "../Logo/Logo";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PlaceIcon from "@mui/icons-material/Place";
import { Link } from "react-router-dom";

const { SubMenu } = Menu;

function MenuEscritorio() {
  return (
    <div className="Container">
      <div className="menuTop">
        <section className="menuTop__Correo">
          <MailOutlineIcon
            color="#2196F3"
            className="marcas__lista__Ubicacion"
          />{" "}
          info@atdsas.com
        </section>
        <section className="menuTop__Telefono">
          <WhatsAppIcon color="#2196F3" className="marcas__lista__Ubicacion" />
          (+57)311 8203545
        </section>
        <section className="menuTop__Logo">
          <Logo forPage="header" />
        </section>
        <section className="menuTop__Ubicacion">
          <PlaceIcon color="#2196F3" className="marcas__lista__Ubicacion" />
          Calle 93 # 15-59 Ofc.303, Bogotá
        </section>
      </div>
      <Menu className="menu" mode="horizontal">
        <Menu.Item key="inicio">
          <Link to="/">Inicio</Link>
        </Menu.Item>
        <Menu.Item key="productos">
          <Link to="/Marcas">Productos y Catálogos</Link>
        </Menu.Item>
        <SubMenu key="marcas" title="Marcas">
          <Menu.Item key="soldamatic">
            <Link to="/Marcas/Soldamatic">Soldamatic</Link>
          </Menu.Item>
          <Menu.Item key="fischertechnik">
            <Link to="/Marcas/Fischertechnik">Fischertechnik</Link>
          </Menu.Item>
          <Menu.Item key="proxxon">
            <Link to="/Marcas/Proxxon">Proxxon</Link>
          </Menu.Item>
          <Menu.Item key="christiani">
            <Link to="/Marcas/Christiani">Christiani</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="clientes">
          <Link to="/Clientes">Clientes y Aliados</Link>
        </Menu.Item>
        <Menu.Item key="contacto">
          <Link to="/Contacto">Contacto</Link>
        </Menu.Item>
      </Menu>
    </div>
  );
}

export default MenuEscritorio;
