import React from "react";
// import { Link } from "react-router-dom";
import Mapa from "../../components/Mapa/Mapa";
import SliderPicture from "../../components/Slider/SliderPicture";
import SliderPicture2 from "../../components/Slider/SliderPicture2";
import "./Home.scss";
import SliderPic from "../../components/Slider/Slider.jsx";
import Imgmarcas from "../../components/ImgMarcas/Imgmarcas";
import { useEffect } from "react";
import { useState } from "react";
import SliderPicture3 from "../../components/Slider/SliderPicture3";
import PlaceIcon from "@mui/icons-material/Place";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { RUOTES_MARCAS, RUOTES_CLIENTS } from "../../utils/AxiosAPI";
import getData from "../../utils/GetDataApi";
import CardServices from "../../components/CardServices/CardServices";
import SliderPicture4 from "../../components/Slider/SliderPicture4";
import SliderPic2 from "../../components/Slider/Slider2";
import { Link } from "react-router-dom";
import { Carousel } from "antd";
import ModalEventos from "../ModalEventos/ModalEventos";
import YouTubeSlider from "../../components/YoutubeSlider/YouTubeSlider";
import videoLinks from "../../utils/VideoNovedades";

function Home() {
  const [marcas, setMarcas] = useState([]);
  const [imagenes, setImagenes] = useState([]);

  const getMarcas = async () => {
    const data = await getData(`${RUOTES_MARCAS.getAllMarcas}`, "marcas");
    setMarcas(data);
  };

  const getClientes = async () => {
    const data = await getData(`${RUOTES_CLIENTS.getAllClients}`, "clientes");
    const imgs = [];
    data?.map((client) => imgs.push(client.client_picture));
    setImagenes(imgs);
  };

  useEffect(() => {
    getMarcas();
    getClientes();
  }, []);

  return (
    <main className="Home">
      <ModalEventos />
      <SliderPicture />
      <section className="Home__ServiceContainer">
        <h2>Productos y Servicios</h2>
        <div className="Home__ServiceContainer__Slider">
          <SliderPicture2 />
        </div>
        <div className="Home__ServiceContainer__CardContainer">
          <CardServices />
        </div>
      </section>
      <article className="Home__TituloServicios">
        <div className="Home__Representacion">
          <h2>
            Representante oficial de diferentes marcas de tecnología Alemana
            como son:
          </h2>
          <div className="Home__Representacion__ContenedorImagenes">
            {marcas.map((item) => (
              <Imgmarcas
                key={item.id_brand}
                img={item.brand_img}
                name={item.brand_name}
                products={""}
              />
            ))}
          </div>
          <p>
            Todas ellas con amplia experiencia y extensa gama de productos y
            servicios entorno a las tecnologías de Industria 4.0 y sus campos de
            acción; trabajando y aplicando el enfoque metodológico STEM – STEAM,
            para lograr un significativo y verdadero aprendizaje.
          </p>
        </div>
        <div className="Home__Representacion2">
          <div className="Home__Representacion2__Izq">
            <img src="/assets/Home/Servicios/Izq.png" alt="" />
          </div>
          <div className="Home__Representacion2__Der">
            <h2>
              Representante oficial de diferentes marcas de tecnología Alemana
              como son:
            </h2>
            <article>
              <p>&#8226; Fischertechnik</p>
              <p>&#8226; Christiani</p>
              <p>&#8226; Proxxon</p>
              <p>&#8226; Soldamatic</p>
            </article>
            <p>
              Todas ellas con amplia experiencia y extensa gama de productos y
              servicios entorno a las tecnologías de Industria 4.0 y sus campos
              de acción; trabajando y aplicando el enfoque metodológico STEM –
              STEAM, para lograr un significativo y verdadero aprendizaje.
            </p>
          </div>

          <div className="Home__Representacion2__ContenedorImagenes">
            {marcas.map((item) => (
              <Imgmarcas
                key={item.id_brand}
                img={item.brand_img}
                name={item.brand_name}
                products={""}
              />
            ))}
          </div>
        </div>
      </article>
      <article className="Home__SlideOtros">
        <SliderPicture3 />
      </article>
      <article className="Home__SlideOtros2">
        <SliderPicture4 />
      </article>
      <article className="Home__Clientes">
        <h2>Nuestros clientes y aliados</h2>
        <p>Gracias por confiar en nosotros</p>
        <div className="Home__Clientes__Slide">
          <SliderPic images={imagenes} />
        </div>
        <div className="Home__Clientes__Slide2">
          <SliderPic2 images={imagenes} />
        </div>
      </article>

      <article className="Home__Youtube">
        <h2>Video Novedades</h2>
        <YouTubeSlider videos={videoLinks} />
      </article>

      <h2>Últimas novedades</h2>
      <section className="NewsContainer">
        <article className="Home__News">
          <div className="Home__News__Container">
            <div className="Home__News__Container__Img">
              <img src="/assets/News/Hyperrealsim.png" alt="" />
            </div>
            <div className="Home__News__Container__Texto">
              <img src="/assets/News/hsim.png" alt="" />
              <p>
                HyperReal-SIM es una experiencia multisensorial que combina
                elementos físicos reales con la tecnología de realidad
                aumentada.
              </p>
              <Link to="/News/HyperReal-Sim">Ver más &#10095; </Link>
            </div>
          </div>
        </article>
        <article className="Home__News">
          <div className="Home__News__Container">
            <div className="Home__News__Container__Img">
              <img src="/assets/News/Industria40.png" alt="" />
            </div>
            <div className="Home__News__Container__Texto">
              <h4>Industria 4.0</h4>
              <p>
                La fischertechnik learning factory 4.0 permite mapear y simular
                el proceso de pedido, el proceso de producción y el proceso de
                entrega en pasos de proceso digitalizados.
              </p>
              <Link to="/News/Industria40">Ver más &#10095; </Link>
            </div>
          </div>
        </article>
      </section>

      <article className="Home__NewsCarousel">
        <Carousel className="Dots">
          <div className="Home__News__Container">
            <div className="Home__News__Container__Img">
              <img src="/assets/News/Hyperrealsim.png" alt="" />
            </div>
            <div className="Home__News__Container__Texto">
              <img src="/assets/News/hsim.png" alt="" />
              <p>
                HyperReal-SIM es una experiencia multisensorial que combina
                elementos físicos reales con la tecnología de realidad
                aumentada.
              </p>
              <Link to="/News/HyperReal-Sim">Ver más &#10095; </Link>
            </div>
          </div>
          <div className="Home__News__Container">
            <div className="Home__News__Container__Img">
              <img src="/assets/News/Industria40.png" alt="" />
            </div>
            <div className="Home__News__Container__Texto">
              <h4>Industria 4.0</h4>
              <p>
                La fischertechnik learning factory 4.0 permite mapear y simular
                el proceso de pedido, el proceso de producción y el proceso de
                entrega en pasos de proceso digitalizados.
              </p>
              <Link to="/News/Industria40">Ver más &#10095; </Link>
            </div>
          </div>
        </Carousel>
      </article>

      <article className="Home__Ubicacion">
        <h2>¿Dónde ubicarnos?</h2>
        <p>
          <PlaceIcon color="#006DBF" className="Home__Ubicacion__Icon" /> Calle
          93 # 15-59 Ofc.303, Bogotá
        </p>
        <p>
          <WhatsAppIcon color="#006DBF" className="Home__Ubicacion__Icon" />{" "}
          (+57)311 8203545
        </p>
        <p>
          <AccessTimeIcon color="#006DBF" className="Home__Ubicacion__Icon" />L
          - V: 8:00 18:00
        </p>
        <div className="Home__Ubicacion__MapContainer">
          <Mapa lat={4.677262251890315} lng={-74.0525841313036} />
        </div>
      </article>
      <article className="Home__Ubicacion2">
        <h2>¿Dónde ubicarnos?</h2>
        <div>
          <p>
            <PlaceIcon color="#006DBF" className="Home__Ubicacion2__Icon" />{" "}
            Calle 93 # 15-59 Ofc.303, Bogotá
          </p>
          <p>
            <WhatsAppIcon color="#006DBF" className="Home__Ubicacion2__Icon" />{" "}
            (+57)311 8203545
          </p>
          <p>
            <AccessTimeIcon
              color="#006DBF"
              className="Home__Ubicacion2__Icon"
            />
            L - V: 8:00 18:00
          </p>
        </div>
        <div className="Home__Ubicacion2__MapContainer">
          <Mapa lat={4.6770528550640975} lng={-74.05257599479854} />
        </div>
      </article>
    </main>
  );
}

export default Home;
