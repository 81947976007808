import React from "react";
import "./HeaderPages.scss"

function HeaderPages({ brand }) {
  let backgroundImg =
    brand === "Fischertechnik"
      ? "/assets/Marcas/Page/Fischertechnik/HeaderFischer.png"
      : brand === "Proxxon"
      ? "/assets/Marcas/Page/Proxxon/HeaderProxxon.png"
      : brand === "Christiani"
      ? "/assets/Marcas/Page/Christiani/HeaderChristiani.png"
      : brand === "Soldamatic" &&
        "/assets/Marcas/Page/Soldamatic/HeaderSolda.png";

  let LogoHead =
    brand === "Fischertechnik"
      ? "/assets/Marcas/Page/Fischertechnik/LogoFischer.png"
      : brand === "Proxxon"
      ? "/assets/Marcas/Page/Proxxon/Proxxon.jpg"
      : brand === "Christiani"
      ? "/assets/Marcas/Page/Christiani/Christiani.jpg"
      : brand === "Soldamatic" && "/assets/Marcas/Soldamatic.png";
  return (
    <section
      className="HeaderPages"
      style={{
        background: `linear-gradient(
      0deg,
      rgba(33, 150, 243, 0.5),
      rgba(33, 150, 243, 0.5)
    ),url(${backgroundImg})`,
        backgroundSize: "cover",
        overflow: "hidden",
      }}
    >
      <div className="HeaderPages__DivImg">
        <img src={LogoHead} alt="Logo" />
      </div>
    </section>
  );
}

export default HeaderPages;
