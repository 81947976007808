import { Modal } from "antd";
import React from "react";
import { useState } from "react";
import "./Videos.scss";
import Play from "@mui/icons-material/PlayCircleOutline";
import ReactPlayer from "react-player";
import timeout from "../../utils/TimeOut";


function Videos(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [play, setPlay] = useState(true);

  const showModal = async () => {
    setIsModalOpen(true);
    await timeout(100);
    setPlay(true);
  };
  const handleCancel = async () => {
    setPlay(false);
    await timeout(100);
    setIsModalOpen(false);
  };

  return (
    <>
      <button className="ButtonV" onClick={showModal}>
        <div className="ButtonV__Container">
          <div className="ButtonV__Container__Play">
            <Play style={{ fontSize: "90px" }} />
            <div className="ButtonV__Container__Play__Text">
              <h3>{props.Titulo}</h3>
              <article>{props.Description}</article>
            </div>
          </div>
          <img src={props.imgButton} alt="ImgButton" />
        </div>
      </button>
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        bodyStyle={{ padding: 0 }}
        width={800}
        centered={true}
      >
        <div className="ContainerVideo">
          <ReactPlayer
            className="ContainerVideo__ReactPlayer"
            width="100%"
            height="100%"
            url={props.Video}
            playing={play}
            controls={true}
            onReady={()=> setPlay(true)}
          />
        </div>
      </Modal>
    </>
  );
}

export default Videos;
