import React from "react";
import Slider3 from "../../utils/images3";
import "./SliderPicture3.scss";
import { Carousel } from "antd";
import { Link } from "react-router-dom";

function SliderPicture3() {
  return (
    <Carousel className="Dots">
      {Slider3.map((item, i) => (
        <div key={i} className="Slider3">
          <div className="Slider3__Div">
            <img src={item.imagen} alt="" />
            <div>
              <p>{item.descripcion}</p>
              <Link to={item.link}>Ver más</Link>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
}

export default SliderPicture3;
