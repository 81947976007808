import "./ProductosStyles.scss";
import React from "react";
import ProductHeader from "../../layoud/ProductLayoud/ProductHeader";
import SearchBar from "../../components/SearchBar/SearchBar";
import getData from "../../utils/GetDataApi";
import { useState, useEffect } from "react";
import { RUOTES_PRODUCTOS, RUOTES_CATALOGOS } from "../../utils/AxiosAPI";
import SliderPdf from "../../components/SliderPdf/SliderPdf";
import SearchBarDesktop from "../../components/SearchBarDesktop/SearchBarDesktop";

function PrdChristiani() {
  const backgroundImg = "/assets/Marcas/Page/Fischertechnik/HeaderFischer.png";
  const logo = "/assets/Marcas/Page/Christiani/Christiani.jpg";
  const brand = "Christiani";
  const brandId = "70ddb859-923c-43cb-a9d4-762fa02d216a";
  const tituloPage = "ChristianiProducts";

  const [products, setProducts] = useState([]);
  const [catalogos, setCatalogos] = useState([]);

  const getProductos = async () => {
    const data = await getData(
      `${RUOTES_PRODUCTOS.getProductsByBrand}${brandId}`,
      `${tituloPage}`
    );
    setProducts(data);
  };
  const getCatalogos = async () => {
    const data = await getData(
      `${RUOTES_CATALOGOS.getCatalogoByBrand}${brand}`,
      `${brand}`
    );
    setCatalogos(data);
  };

  useEffect(() => {
    getProductos();
    getCatalogos();
  }, []);

  return (
    <>
      <ProductHeader brand={brand} logo={logo} backgroundImg={backgroundImg} />
      <div className="PrdPage">
        <section className="Titulo">
          <h2>Productos Christiani</h2>
        </section>
        <section className="SearchBarMobile">
          <SearchBar data={products} brand={brand} items={3} />
        </section>
        <section className="SearchBarDesktop">
          <SearchBarDesktop data={products} brand={brand} items={10} />
        </section>
      </div>
      <article className="BrandPage__Catalogos__SlidePDF">
        <h3>Catálogos</h3>
        <SliderPdf catalogos={catalogos} />
      </article>
    </>
  );
}

export default PrdChristiani;
