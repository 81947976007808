import React, { useState } from "react";
import "./Tarjetas.scss";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import People from "@mui/icons-material/SupervisedUserCircle";
import TaskIcon from "@mui/icons-material/TaskAlt";
import { Modal } from "antd";

function Tarjetas(props) {
  const [modalOpen, setModalOpen] = useState(false);

  const backgroundImage = props.back;
  const logoSoldi = props.log;

  const showModal = () => {
    setModalOpen(true);
  };

  const handleCancel = () => {
    setModalOpen(false);
  };
  return (
    <>
      <div
        className="TarjetasContainer"
        style={{ backgroundImage: `url(${backgroundImage})` }}
        onClick={showModal}
      >
        <img src={logoSoldi} alt="" />
        <TouchAppIcon
          className="TarjetasContainer__Icono"
          sx={{ fontSize: 50 }}
        />
      </div>
      <Modal
        open={modalOpen}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        bodyStyle={{ padding: "0px" }}
        width={800}
        centered={true}
      >
        <div
          className="ModalContainer"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            borderRadius: "8px",
          }}
        >
          <img src={logoSoldi} alt="" />
          <p>{props.text1}</p>
          <People
            sx={{ fontSize: 50 }}
            className={`ModalContainer${props.color}`}
          />
          <p>{props.text2}</p>
          <TaskIcon
            sx={{ fontSize: 50 }}
            className={`ModalContainer${props.color}`}
          />
          <p>{props.text3}</p>
        </div>
      </Modal>
    </>
  );
}

export default Tarjetas;
