import React, { useState } from "react";
import "./SliderVertical.scss";
import { useEffect } from "react";
import { Modal } from "antd";

function SliderVertical({ images }) {
  const [principal, setPrincipal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const showModal = () => {
    setModalOpen(true);
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    setPrincipal(`${images[0]}`);
  }, [images]);
  return (
    <>
      
      <div className="SlidervContainer">
        <div className="SlidervContainer__Izq">
          {images.map((img, i) => (
            <div key={i}>
              <img
                onClick={() => setPrincipal(img)}
                src={`/${process.env.PUBLIC_URL}${img}`}
                alt={img}
              />
            </div>
          ))}
        </div>
        <div className="SlidervContainer__Der">
          <div onClick={() => showModal()}>
            <img
              src={`/${process.env.PUBLIC_URL}${principal}`}
              alt={principal}
            />
          </div>
        </div>
      </div>
      <Modal
        open={modalOpen}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        bodyStyle={{ margin: "0px" }}
        width={800}
        centered={true}
      >
        <div className="SlidervContainer__Modal">
          <img src={`/${process.env.PUBLIC_URL}${principal}`} alt={principal} />
        </div>
      </Modal>
    </>
  );
}

export default SliderVertical;
