
const sliderData3 = [
  {
    imagen: "assets/Home/Servicios/Equipos.jpg",
    nombre: "Equipos y tecnología",
    descripcion: "Equipos y tecnología",
    link: "/Marcas",
  },

  {
    imagen: "assets/Home/Servicios/Industria4.jpg",
    nombre: "Naruto 2",
    descripcion: "Industria 4.0",
    link: "/",
  },

  {
    imagen: "assets/Home/Servicios/Portada_Proxxon.png",
    nombre: "Naruto 3",
    descripcion: "Catálogos",
    link: "/",
  }
];
export default sliderData3;
