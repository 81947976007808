import React from "react";
import HeaderPages from "../../components/HeaderPages/HeaderPages";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import "./HyperRealSim.scss";
import Back from "@mui/icons-material/Reply";
import Videos from "../../components/Videos/Videos";
import ButtonImg from "../../assets/ButtonsImg/Soldadura.jpg";

function HyperRealSim() {
  const Soldamatic = "Soldamatic";
  //Video
  const tituloPage = Soldamatic;
  const descriptionPage = "";
  const video = "https://www.youtube.com/watch?v=DVpqKwbA1us";

  const handleBack = () => {
    window.history.back();
  };

  return (
    <div>
      <HeaderPages brand={Soldamatic} />
      <section className="BrandPage__Bread">
        <Breadcrumb />
      </section>
      <section className="NewContainer">
        <section className="NewContainer__ImgTitle">
          <section className="ProductContainer__Header">
            <Back
              onClick={handleBack}
              fontSize="large"
              color="primary"
              className="ProductContainer__Back"
            />
            <h3>HyperReal-SIM</h3>
          </section>
        </section>
        <article className="NewContainer__Text">
          <p>&nbsp;</p>
          <p>
            HyperReal-SIM es una experiencia multisensorial que combina
            elementos físicos reales con la tecnología de realidad aumentada. La
            tecnología patentada HyperReal-SIM de Soldamatic incluye equipos
            reales, como una antorcha y una máscara de soldadura, así como un
            proceso de parametrización propio. La parametrización de Soldamatic
            comprende el estudio del comportamiento de los diferentes
            procedimientos de soldadura (WPS) en un entorno real y bajo
            diferentes escenarios. Los resultados se toman como métricas en el
            software de simulación, creando una experiencia hiperrealista de
            soldadura en la plataforma de formación profesional más completa que
            existe.
          </p>
          <p>&nbsp;</p>
          <p>
            A través de los fundamentos y conceptos de las disciplinas que
            comprenden la mecatrónica – (mecánica, electrónica, informática y
            control automático) y ligado también a otras ramas de la ciencia y
            física aplicada que en su conjunto engloban el enfoque STEM
            (energías renovables o la óptica), los niños y jóvenes construyen su
            conocimiento, y desarrollan habilidades del pensamiento como
            herramientas para todas la etapas de la vida.
          </p>
          <p>&nbsp;</p>
          <Videos
            imgButton={ButtonImg}
            Titulo={tituloPage}
            Description={descriptionPage}
            Video={video}
          />
        </article>
        <p>&nbsp;</p>
        <h3>¿Por qué HyperReal-SIM?</h3>
        <article className="NewContainer__Text">
          <p>&nbsp;</p>
          <p>
            La soldadura no es un juego, la correcta formación y desarrollo de
            habilidades de un soldador es crucial, un error mínimo en una
            soldadura puede suponer una catástrofe o pérdidas millonarias. Los
            sistemas y metodologías de formación en soldadura deben garantizar
            que los soldadores pueden integrarse en el mercado laboral
            cumpliendo los altos estándares de calidad exigidos por la
            industria.
          </p>
          <p>&nbsp;</p>
          <p>
            Soldamatic permite que tanto los futuros soldadores como soldadores
            profesionales puedan desarrollar sus habilidades y conocimientos con
            una herramienta profesional y precisa gracias al HyperReal-SIM by
            Seabery, que consiste en la aplicación de un conjunto de tecnologías
            que combinando elementos reales y elementos en Realidad Aumentada
            crea una experiencia de usuario similar y próxima a la realidad.
          </p>
        </article>
        <p>&nbsp;</p>
        <section className="NewContainer__ImgContainer">
          <img src="/assets/News/hsim.png" alt="" />
        </section>
        <article className="NewContainer__Text">
          <p>&nbsp;</p>
          <h4>Parametrización</h4>
          <p>&nbsp;</p>
          <p>
            La simulación de la soldadura se parametriza en base al
            comportamiento de una WPS en un entorno real y bajo diferentes
            escenarios (por ejemplo, análisis de macrografías, videograbación
            del baño de soldadura, el sonido de la soldadura, etc.); los
            resultados se toman como entradas en el proceso de desarrollo del
            software recogiendo todas las variables: sonido, imagen, baño,
            tiempo…, y los algoritmos “traducen” esta información en el código
            que se aplica a la simulación.
          </p>
        </article>
        <article className="NewContainer__Text">
          <p>&nbsp;</p>
          <h4>Sonido Real</h4>
          <p>&nbsp;</p>
          <p>
            Lo que se escucha durante la simulación es real se ha grabado en el
            taller de soldadura.
          </p>
        </article>
        <article className="NewContainer__Text">
          <p>&nbsp;</p>
          <h4>Realidad Aumentada</h4>
          <p>&nbsp;</p>
          <p>
            Integra en el mundo real la simulación, junto con los diferentes
            elementos utilizados en el proceso de soldadura real: antorcha,
            casco, piezas y equipo, permitiendo al usuario interactuar con la
            práctica utilizando los movimientos naturales del cuerpo, tal y como
            si estuviese realizando una práctica real; desarrollando la memoria
            muscular y ejercitando la postura real.
          </p>
        </article>
        <article className="NewContainer__Text">
          <p>&nbsp;</p>
          <h4>Componentes</h4>
          <p>&nbsp;</p>
          <p>
            <strong>Equipo de soldadura</strong>
            <p>&nbsp;</p>
            Diseño similar a un equipo de soldadura, con un panel frontal
            similar a un equipo de soldadura real, pudiendo modificar la
            tensión, el amperaje, la polaridad, el gas, etc.
            <p>&nbsp;</p>
            <strong>Controlador/servidor de pie</strong>
            <p>&nbsp;</p>
            de amperaje real para soldadura GTAW/TIG
            <p>&nbsp;</p>
            <strong>Antorchas reales</strong>
            <p>&nbsp;</p>
            para soldadura GMAW, GTAW, FCAW y MMA. Fabricadas por Abicor Binzel.
            <p>&nbsp;</p>
            <strong>Máscara de soldadura real</strong>
            <p>&nbsp;</p>
            <strong>Guantes de soldadura real</strong>
            <p>&nbsp;</p>
          </p>
          <section className="NewContainer__Text__ImgContainer">
            <img src="/assets/News/Componentes.png" alt="" />
          </section>
        </article>
        <p>&nbsp;</p>
        <h3>Novedades de Soldamatic 2023</h3>
        <article className="NewContainer__Text">
          <p>&nbsp;</p>
          <p>
            Si deseas ver más novedades puedes visitar la pagina de Seabery en
            la sección de novedades.
          </p>
        </article>
        <p>&nbsp;</p>
        <button class="shadow__btn">
          <a
            href="https://seaberyat.com/es/soldamatic-2023-simulador-soldadura/"
            target="_blank"
            rel="noreferrer"
          >
            Soldamatic 2023
          </a>
        </button>
        <p>&nbsp;</p>
      </section>
    </div>
  );
}

export default HyperRealSim;
