import React from 'react';
import './WhatsAppButton.scss'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const WhatsAppButton = () => {
  return (
    <a href="https://wa.me/573118203545" target="_blank" rel="noopener noreferrer" className="whatsapp-button">
      <WhatsAppIcon style={{ fontSize: 32, color: "#FFFFFF" }} />
    </a>
  );
};

export default WhatsAppButton;