import React from "react";
import "./FooterMenuMobile.scss";
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

function FooterMenuMobile() {
  const getYear = () => {
    return new Date().getFullYear();
  };

  return (
    <footer className="footer-container-mobile">
      <section className="footer-redes">
        <div>Siguenos en:</div>
        <div className="footer-redes__icons">
          <div className="footer-redes__icons__div">
            <FacebookIcon/> 
          </div>
          <div className="footer-redes__icons__div">
            <LinkedInIcon/> 
          </div>
          <div className="footer-redes__icons__div">
            <TwitterIcon/>
          </div>
        </div>

        <div>© Todos los derechos reservados {getYear()}</div>
      </section>
    </footer>
  );
}

export default FooterMenuMobile;
