import React from "react";

function TextOrganizer2({ text }) {
  // Separar el texto por puntos aparte
  const sections = text?.split("\n");

  // Crear una etiqueta <p> por cada sección del texto
  const paragraphs = sections?.map((section, i) => (
    <p key={i}>- {`${section.trim()}${i !== i.length -1 && "."}`}</p>
  ));

  // Retornar las etiquetas <p>
  return <>{paragraphs}</>;
}

export default TextOrganizer2;
