import React from "react";
import ContactHeader from "../../layoud/ProductLayoud/ContactHeader";
import "./ClientesAliados.scss";
import { useState } from "react";
import getData from "../../utils/GetDataApi";
import { RUOTES_CLIENTS } from "../../utils/AxiosAPI";
import { useEffect } from "react";
import Empresa from "@mui/icons-material/TurnedInNot";
import Escuela from "@mui/icons-material/School";
import { Pagination } from "@mui/material";

function ClientesAliados() {
  const backgroundImg = "/assets/Marcas/Page/Fischertechnik/HeaderFischer.png";
  const logo = "/assets/Marcas/Page/Fischertechnik/LogoFischer.png";
  const Titulo = "Clientes y Aliados";

  const [clients, setClients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const getClientes = async () => {
    const data = await getData(`${RUOTES_CLIENTS.getAllClients}`, `clientes`);
    setClients(data);
  };

  const itemsPerPage = 14;
  const totalPages = Math.ceil(clients.length / itemsPerPage);
  const paginatedResults = [];
  for (let i = 0; i < totalPages; i++) {
    const startIndex = i * itemsPerPage;
    const pageResults = clients.slice(startIndex, startIndex + itemsPerPage);
    paginatedResults.push(pageResults);
  }

  useEffect(() => {
    getClientes();
  }, []);

  return (
    <div>
      <ContactHeader
        Titulo={Titulo}
        logo={logo}
        backgroundImg={backgroundImg}
      />
      <section className="ClientesContainer">
        <section className="ClientesContainer__CardsContainer">
          {paginatedResults[currentPage - 1]?.map((item, i) => (
            <article
              key={i}
              className="ClientesContainer__CardsContainer__Cards"
            >
              <header>
                <p>
                  {item.cliente_category === "Colegio" ||
                  item.cliente_category === "Universidad" ? (
                    <Escuela />
                  ) : (
                    <Empresa />
                  )}
                  {item.cliente_category}
                </p>
                <img
                  src={`/${process.env.PUBLIC_URL}${item.client_picture}`}
                  alt={item.client_name}
                />
              </header>
              <footer>
                <a href={item.client_web} target="_blank" rel="noreferrer">
                  <p>{item.client_name}</p>
                </a>
              </footer>
            </article>
          ))}
        </section>
      </section>
      <div className="Pagination">
        <Pagination
          count={Math.ceil(clients.length / itemsPerPage)}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
          color="primary"
        />
      </div>
    </div>
  );
}

export default ClientesAliados;
