import "./App.scss";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import UserLayoud from "./layoud/UserLayoud/UserLayoud";
import React from "react";
import { useThemeContext } from "./context/ThemeContext";
import Soldamatic from "./pages/BrandsPages/Soldamatic";
import Marcas from "./pages/Marcas/Marcas";
import Fischertechnik from "./pages/BrandsPages/Fischertechnik";
import Proxxon from "./pages/BrandsPages/Proxxon";
import Christiani from "./pages/BrandsPages/Christiani";
import PrdFischertechnik from "./pages/Productos/PrdFischertechnik";
import ProductId from "./pages/ProductId/ProductId";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Contacto from "./pages/Contacto/Contacto";
import HyperRealSim from "./pages/News/HyperRealSim";
import ClientesAliados from "./pages/ClientesAliados/ClientesAliados";
import Industria40 from "./pages/News/Industria40";
import PrdChristiani from "./pages/Productos/PrdChristiani";

function App() {
  const { contextTheme } = useThemeContext();
  document.body.style.overflow = "auto";

  return (
    <div className="App" id={contextTheme}>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<UserLayoud />}>
          <Route path="/" element={<Home />} />
          <Route path="/Marcas" element={<Marcas />} />
          <Route path="/Marcas/Soldamatic" element={<Soldamatic />} />
          <Route path="/Marcas/Fischertechnik" element={<Fischertechnik />} />
          <Route
            path="/Marcas/Fischertechnik/Productos"
            element={<PrdFischertechnik />}
          />
          <Route
            path="/Marcas/Christiani/Productos"
            element={<PrdChristiani />}
          />
          <Route path="/Marcas/Christiani" element={<Christiani />} />
          <Route path="/Marcas/Proxxon" element={<Proxxon />} />
          <Route path="/Product/:brand/:id" element={<ProductId />} />
          <Route path="/Clientes" element={<ClientesAliados />} />
          <Route path="/Contacto" element={<Contacto />} />
          <Route path="/News/HyperReal-Sim" element={<HyperRealSim />} />
          <Route path="/News/Industria40" element={<Industria40 />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
