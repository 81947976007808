import {
  configAxios,
  RUOTES_MARCAS,
  RUOTES_CLIENTS,
  RUOTES_CATALOGOS,
  RUOTES_PRODUCTOS,
} from "../utils/AxiosAPI";
import axios from "axios";

const getDataFromApi = async (url, cache) => {
  const response = await axios.get(url, configAxios);
  localStorage.setItem(cache, JSON.stringify(response?.data?.data));
  const data = response?.data?.data;
  return data;
};

const updateDataFromApi = async () => {
  // Programar una tarea para que se ejecute una vez al día y obtenga los datos más recientes de la API
  localStorage.clear();
  await getDataFromApi(`${RUOTES_MARCAS.getAllMarcas}`, "marcas");
  await getDataFromApi(`${RUOTES_CLIENTS.getAllClients}`, "clientes");
  await getDataFromApi(`${RUOTES_CATALOGOS.getAllCatalogos}`, "allCatalogos");
  await getDataFromApi(
    `${RUOTES_CATALOGOS.getCatalogoByBrand}Fischertechnik`,
    "Fischertechnik"
  );
  await getDataFromApi(
    `${RUOTES_CATALOGOS.getCatalogoByBrand}Proxxon`,
    "Proxxon"
  );
  await getDataFromApi(
    `${RUOTES_CATALOGOS.getCatalogoByBrand}Soldamatic`,
    "Soldamatic"
  );
  await getDataFromApi(
    `${RUOTES_CATALOGOS.getCatalogoByBrand}Christiani`,
    "Christiani"
  );
  await getDataFromApi(`${RUOTES_PRODUCTOS.getAllProducts}`, "AllProducts");
  await getDataFromApi(
    `${RUOTES_PRODUCTOS.getProductsByBrand}1df398bd-d8ac-4d2e-b95a-572fc4e663b4`,
    "FischertechnikProducts"
  );
  await getDataFromApi(
    `${RUOTES_PRODUCTOS.getProductsByBrand}ce98e064-44a2-475b-bd4d-f995d0f3bb12`,
    "ProxxonProducts"
  );
  await getDataFromApi(
    `${RUOTES_PRODUCTOS.getProductsByBrand}70ddb859-923c-43cb-a9d4-762fa02d216a`,
    "ChristianiProducts"
  );
};

export default updateDataFromApi;
